<div class="page-title" style="background-image: url('assets/images/landing/faq/title-bg.webp')">
  <h1 class="h1-semibold head-font">Frequently Asked Questions</h1>
</div>
<div class="faq-banner" style="background-image: url('assets/images/landing/faq/banner-bg.webp')">
  <div class="container px-5">
    &nbsp;
  </div>
</div>
<section class="page-wrap animated fadeIn" >
  <div class="container">
    <div ngbAccordion [closeOthers]="true">
      @for (item of faq; track item) {
        <div ngbAccordionItem [collapsed]="true">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>{{ item.question }}</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <div [innerHTML]="sanitizeHtml(item.answer)">
              </div>
            </div>
          </div>
        </div>
      }
    </div>

  </div>
</section>

