<div class="main-container__full animated fadeIn">

  <!-- Nav -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" aria-current="page">
        <a [routerLink]="['/shop']" class="icon-back"></a>
        Home
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        <a href="javascript:void(0)" class="fw-bolder text-primary">Favorites / Dislikes</a>
      </li>
    </ol>
  </nav>

  <!-- Favorites -->
  @defer (on immediate; when likes().length) {
  <section>
    <h1 class="fw-bolder animated fadeInLeft mt-4">
      Favorites
    </h1>
  </section>
  <section class="row">
    @if (likes().length > 0) {
    @for (product of likes(); track product.id) {
    @defer (on viewport; prefetch on immediate) {
    @if (isMobile()) {
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 animated fadeIn">
      <div class="product-card-grid__item">
        <app-mobile-product-card [card]="{product}"
          (outClickFavoriteOrDislike)="changeFavoriteState($event)"></app-mobile-product-card>
      </div>
    </div>
    } @else {
    <div class="col-xl-3 col-lg-4 col-sm-6 animated fadeIn">
      <div class="product-card-grid__item">
        <app-product-card [card]="{ product }"
          (outClickFavoriteOrDislike)="changeFavoriteState($event)"></app-product-card>
      </div>
    </div>
    }
    } @placeholder {
    <div class="col-xl-3 col-lg-4 col-sm-6 animated fadeInUp fadeIn mb-3">
      <app-skeleton-product-card></app-skeleton-product-card>
    </div>
    }
    }
    } @else {
    <app-empty-message [message]="'There are no products marked as favorites.'"></app-empty-message>
    }
  </section>
  }

  <!-- Dislikes -->
  @defer (on immediate; when dislikes().length) {
  <section>
    <h1 class="fw-bolder animated fadeInLeft mt-4">
      Dislikes
    </h1>
  </section>
  <section class="row">
    @if (dislikes().length > 0) {
    @for (product of dislikes(); track product.id) {
    @defer (on viewport; prefetch on immediate) {
    @if (isMobile()) {
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 animated fadeIn">
      <div class="product-card-grid__item">
        <app-mobile-product-card [card]="{product}"
          (outClickFavoriteOrDislike)="changeDislikeState($event)"></app-mobile-product-card>
      </div>
    </div>
    } @else {
    <div class="col-xl-3 col-lg-4 col-sm-6 animated fadeIn">
      <div class="product-card-grid__item">
        <app-product-card [card]="{ product }"
          (outClickFavoriteOrDislike)="changeDislikeState($event)"></app-product-card>
      </div>
    </div>
    }
    } @placeholder {
    <div class="col-xl-3 col-lg-4 col-sm-6 animated fadeInUp fadeIn mb-3">
      <app-skeleton-product-card></app-skeleton-product-card>
    </div>
    }
    }
    }@else {
    <app-empty-message [message]="'There are no products marked as disliked.'"></app-empty-message>
    }
  </section>
  }
</div>