import { Component, Inject, OnInit, PLATFORM_ID, computed, inject, signal, } from '@angular/core';
import { PagesService } from '../pages.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ProducersService } from './producers.service';
import { handleImageError } from '../../shared/common/utils';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-producers',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './producers.component.html',
  styleUrl: './producers.component.scss',
})
export class ProducersComponent implements OnInit {

  //#region Services

  private producersService = inject(ProducersService);

  private sanitizer = inject(DomSanitizer);

  pagesService = inject(PagesService);

  //#endregion

  //#region Constructor

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit() {
    this.pagesService.restartSideMenu();
    if (isPlatformBrowser(this.platformId))
      this.producersService.getAllProducers();
  }

  //#endregion

  //#region Properties

  handleImageError = handleImageError;

  producersCards = computed<any[]>(() => {
    const producers = this.producersService.producersSignal();
    if (!producers) return [];

    return producers.map((p: any) => {
      const desc = (p.description || '');
      const truncatedDescription = this.truncateHtml(desc, 356, p.detailUrl);
      p.description = truncatedDescription;
      return p
    });
  });

  loadingProducers = computed(() => !!this.producersService.isLoadingProducers());

  producersInView = computed(() => this.#getProducersInView());

  showLoadMore = computed(() => this.producersCards().length > (this.size * (this.page() + 1)));

  page = signal(0);

  size = 20;

  //#endregion

  //#region Methods

  truncateHtml(html: string, maxLength: number, url: string): SafeHtml {
    if (!isPlatformBrowser(this.platformId)) return '';
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    let charCount = 0;
    const truncateNode = (node: Node): boolean => {
      if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent || '';
        if (charCount + textContent.length > maxLength) {
          const truncatedText = textContent.slice(0, maxLength - charCount) + '... ';
          node.textContent = truncatedText;
          return true;
        } else {
          charCount += textContent.length;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const childNodes = Array.from(node.childNodes);
        for (const childNode of childNodes) {
          if (truncateNode(childNode)) {
            return true;
          }
        }
      }
      return false;
    };

    truncateNode(tempDiv);

    return this.sanitizer.bypassSecurityTrustHtml(tempDiv.innerHTML);
  }

  loadMore() {
    this.page.update(value => value + 1);
  }

  #getProducersInView() {
    return this.producersCards().slice(0, this.size * (this.page() + 1));
  }

  //#endregion
}
