<div class="page-title" style="background-image: url('assets/images/landing/about/title-bg.webp')">
  <h1 class="h1-semibold head-font">How it works</h1>
</div>
<section class="page-wrap animated fadeIn">
  <div class="py-5">
    <div class="how-step-wrap has-animation fadeInUp">
      <div *ngFor="let item of howSteps" class="how-step {{item.alternate}}">
        <span class="icon">{{item.step}}</span>
        <div class="detail">
          <img src="{{item.img}}" />
          <div class="text">{{item.text}}</div>
          <ul class="ul-list">
            <li *ngFor="let list of item.ulText">{{list}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Best Selling Products -->
@defer(on viewport) {
@if (bestSellingProducts().length) {
<div class="banner py-5 px-5 bg-primary-subtle text-center">
  <h3 class="h2-semibold head-font text-center mb-3">
    Best Selling Products
  </h3>
  <div class="row">
    <div class="col-md-12">
      <app-carousel [items]="bestSellingProducts()" type="producerProduct"></app-carousel>
    </div>
  </div>
</div>
}
} @placeholder {<section></section>}