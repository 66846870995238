import { Injectable, inject } from '@angular/core';
import { isANoDeliveryDayUser, isANoPaymentMethodUser, isNoAddressUser } from '../../../shared/common/utils';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { OrderService } from '../../../shared/order.service';
import { ModalContentService } from '../../../shared/modal-content/modal-content.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from '../../../shared/local-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodSignupService {

  #localStorageService = inject(LocalStorageService);
  #signalsStoreService = inject(SignalsStoreService);
  #orderService = inject(OrderService);
  #modalContentService = inject(ModalContentService);
  #activeModal = inject(NgbModal);
  #router = inject(Router);

  constructor() { }

  updateOrder(showDefaultMessage: boolean) {

    if (isANoDeliveryDayUser(this.#localStorageService, this.#modalContentService))
      return;

    if (isANoPaymentMethodUser(this.#localStorageService, this.#modalContentService, this.#activeModal))
      return;

    if (isNoAddressUser(this.#localStorageService, this.#modalContentService, this.#activeModal, this.#router))
      return;

    return this.#orderService
      .updateOrder({
        order: this.#setUpFirebaseOrder(),
        firebaseOrderSignal: this.#signalsStoreService.firebaseOrder,
        orderId: undefined,
        showDefaultMessage
      });
  }

  #setUpFirebaseOrder() {
    const firebaseOrder = this.#signalsStoreService.firebaseOrder();

    if (!firebaseOrder)
      return null

    if (!firebaseOrder?.products)
      return firebaseOrder

    firebaseOrder.products.common = this.#processProducts(firebaseOrder.products.common || []);
    firebaseOrder.products.subscription = this.#processProducts(firebaseOrder.products.subscription || []);

    return firebaseOrder;
  }

  #calculateProductTotalPrice(product: any): number {
    let totalPrice = 0;
    if (product?.bundle?.items) {
      if (product.bundle.isFixed) {
        totalPrice = +product.price;
        const premiumItems = product?.bundle?.items?.filter((pi: any) => pi.isPremiumAddon);
        if (premiumItems?.length) {
          totalPrice += premiumItems.reduce((total: number, bundleItem: any) => {
            const bundleItemPrice = (+bundleItem?.price * +bundleItem?.quantity) || 0
            return total + bundleItemPrice
          }, 0);
        }
      } else if (product?.bundle?.items?.length)
        totalPrice = product.bundle.items.reduce((total: number, bundleItem: any) => {
          const bundleItemPrice = (bundleItem?.isRemoved ? 0 : (+bundleItem?.price * +bundleItem?.quantity) || 0)
          return total + bundleItemPrice
        }, 0);
    }
    if (totalPrice === 0)
      totalPrice = product.price

    return totalPrice * product.quantity;
  }

  #processProducts(products: any[]): any[] {
    return products.map(product => {
      const totalPrice = this.#calculateProductTotalPrice(product);
      return { ...product, totalPrice };
    });
  }
}
