import { Component, computed, inject, input, InputSignal } from '@angular/core';
import { PreOrderedProduct } from '../../../../product/product.types';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { depositConfig } from '../../../../product/product.contstants';
import { ResolutionService } from '../../../../shared/resolution.service';

@Component({
  selector: 'app-pre-ordered-product',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './pre-ordered-product.component.html',
  styleUrl: './pre-ordered-product.component.scss'
})
export class PreOrderedProductComponent {

  #resolutionService = inject(ResolutionService);

  product: InputSignal<PreOrderedProduct | undefined> = input(undefined, {
    transform: (data: PreOrderedProduct | undefined) => {
      if (!data) return data;
      const name = data.name;
      const variantName = data.variant?.attribute?.value?.name ?? '';
      data.name = variantName ? `${name} | ${variantName}` : name
      return data;
    }
  });

  isMobile = computed(() => this.#resolutionService.isMobile());

  depositConfig = computed(() => {
    const preorder = this.product()?.preOrder;
    if (!preorder) return null;
    return depositConfig[preorder.depositType]
  })

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
  }
}
