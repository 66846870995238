@if (producerDetails()) {
<div class="detail">

  <!-- Nav -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" (click)="goToHome()">
        <span class="material-symbols-outlined">home</span>
      </li>
      <li class="breadcrumb-item d-flex align-items-center" aria-current="page" (click)="back()">
        <span class="fw-semibold">
          Farmers & Producers
        </span>
      </li>
      <li class="breadcrumb-item d-flex align-items-center" aria-current="page">
        <span class="fw-bold">
          {{producerDetails()?.name}}
        </span>
      </li>
    </ol>
  </nav>

  <!-- Producer info -->
  <section class="mb-3">
    <!-- Main info -->
    <div class="d-none d-md-flex flex-row gap-5">

      <!-- Image -->
      <div class="detail__img w-40">
        <img [src]="producerDetails()?.img" alt="{{ producerDetails()?.name }}" (error)="handleImageError($event)"
          class="w-100" />
      </div>

      <!-- Description -->
      <div class="detail__main w-60">

        <div class="mb-3">
          <h1 class="h1-bold text-secondary mb-0">
            {{producerDetails()?.name}}
          </h1>

          @if (producerDetails()?.location) {
          <h4 class="h7-semibold align-items-center d-flex gap-2 detail__location">
            <span class="material-symbols-outlined">
              location_on
            </span>
            {{producerDetails()?.location}}
          </h4>
          }
        </div>

        <hr class="m-0">

        <div class="mt-3">
          <p [innerHTML]="sanitizeHtml(producerDetails().description)"></p>
        </div>

      </div>

    </div>

    <!-- Main info mobile -->
    <div class="d-md-none">

      <img [src]="producerDetails()?.img" class="img-fluid" alt="{{ producerDetails()?.name }}" />

      <h1 class="h1-bold text-secondary mt-3">
        {{producerDetails()?.name}}
      </h1>

      <h4 class="h7-semibold align-items-center d-flex gap-2 detail__location mb-3">
        <span class="material-symbols-outlined">
          location_on
        </span>
        {{producerDetails()?.location}}
      </h4>

      <hr class="m-0">

      <div class="mt-3">
        <p [innerHTML]="sanitizeHtml(producerDetails().description)"></p>
      </div>

    </div>

  </section>

  <!-- Carousel -->
  @if(producerDetails().products.length) {
  <hr class="mt-4">
  <section class="mt-4">

    <h2 class="h3-bold text-secondary mb-3">
      Our Products
    </h2>

    <app-carousel [items]="carouselProducerProducts()" [type]="'producerProduct'"
      [hasMoreItems]="producerDetails()?.hasMoreProducts">
    </app-carousel>

  </section>
  }
</div>
}