import { Routes } from "@angular/router";
import { PagesComponent } from "./pages.component";
import { ProducersComponent } from "./producers/producers.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { HowItWorksComponent } from "./how-it-works/how-it-works.component";
import { ProcurementStandardsComponent } from "./procurement-standards/procurement-standards.component";
import { DeliveryAreaComponent } from "./how-it-works/delivery-areas/delivery-area.component";
import { ProducerDetailComponent } from "./producers/producer-detail/producer-detail.component";
import { maintenanceGuard } from "../shared/guards/auth-guard.guard";
import { FaqComponent } from "./faq/faq.component";
import { SustainableComponent } from "./sustainable/sustainable.component";
import { RecipesComponent } from "./recipes/recipes.component";
import { RecipeDetailComponent } from "./recipe-detail/recipe-detail.component";
import { BlogComponent } from "./blog/blog.component";
import { BlogPostComponent } from "./blog/blog-post/blog-post.component";
import { GiftCardsComponent } from "./gift-cards/gift-cards.component";
import { WhyFarmboxComponent } from "./why-farmbox/why-farmbox.component";

export const pagesRoutes: Routes = [
  {
    path: 'pages',
    title: 'Pages',
    component: PagesComponent,
    canActivate: [maintenanceGuard],
    canActivateChild: [maintenanceGuard],
    children: [
      {
        path: 'how-it-works',
        title: 'How it works',
        component: HowItWorksComponent
      },
      {
        path: 'delivery-areas',
        title: 'Delivery Area',
        component: DeliveryAreaComponent
      },
      {
        path: 'sustainable',
        title: 'Sustainable',
        component: SustainableComponent
      },
      {
        path: 'recipes',
        title: 'Recipes',
        component: RecipesComponent
      },
      {
        path: 'recipe-detail',
        title: 'Recipe Detail',
        component: RecipeDetailComponent
      },
      {
        path: 'faq',
        title: 'FAQ',
        component: FaqComponent,
      },
      {
        path: 'procurement-standards',
        title: 'Procurement standards',
        component: ProcurementStandardsComponent,
      },
      {
        path: 'producers',
        title: 'Producers',
        component: ProducersComponent
      },
      {
        path: 'producers/:producerUrl',
        title: 'Producer Detail',
        component: ProducerDetailComponent
      },
      {
        path: 'contact-us',
        title: 'Contact Us',
        component: ContactUsComponent
      },
      {
        path: 'blog',
        title: 'Blog',
        component: BlogComponent
      },
      {
        path: 'blog-post/:category/:title',
        title: 'Blog Post',
        component: BlogPostComponent
      },
      {
        path: 'gift-cards',
        title: 'Gift Cards',
        component: GiftCardsComponent
      },
      {
        path: 'why-farmbox',
        title: 'Why Farmbox',
        component: WhyFarmboxComponent
      },
    ]
  },
];
