import { Component, OnInit, inject } from '@angular/core';
import { PagesService } from '../pages.service';
import { FormsModule } from '@angular/forms';
import { NotificationsComponent } from '../../shared/notification/notification.component';
import { NgIf } from '@angular/common';
import { OnlyNumberDirective } from '../../shared/directives/only-number.directive';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    FormsModule, 
    NotificationsComponent, 
    NgIf,
    OnlyNumberDirective
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent implements OnInit {
  pagesService = inject(PagesService);
  
  formData = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: ''
  };
  
  firstNameValidation = { error: '', validations: ['required', 'firstName'] };
  lastNameValidation = { error: '', validations: ['required', 'lastName'] };
  subjectValidation = { error: '', validations: ['required', 'subject'] };
  messageValidation = { error: '', validations: ['required', 'message'] };
  phoneNumberValidation = { error: '', validations: ['required', 'phoneNumber'] };
  emailValidation = { error: '', validations: ['required', 'email'] };

  ngOnInit(): void {
    this.pagesService.restartSideMenu();
  }

  validateField(key: string, value: string | any) {
    if (typeof value !== 'string') {
      value = value.target.value;
    }
  
    let data;
    switch (key) {
      case 'firstName':
        data = this.firstNameValidation;
        break;
      case 'lastName':
        data = this.lastNameValidation;
        break;
      case 'subject':
        data = this.subjectValidation;
        break;
      case 'message':
        data = this.messageValidation;
        break;
      case 'phoneNumber':
        data = this.phoneNumberValidation;
        break;
      case 'email':
        data = this.emailValidation;
        break;
      default:
        return;
    }
  
    let error = '';
    for (const validation of data.validations) {
      switch (validation) {
        case 'required':
          error = !value.trim() ? 'This field is required' : '';
          break;
        case 'phoneNumber':
          error = !(/^\d+$/.test(value)) || value.trim().length !== 10 ? 'Enter a valid phone number' : '';
          break;
        case 'email':
          error = !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value)) ? 'Enter a valid email' : '';
          break;
      }

      data.error = error;
    }
  }

  hasValidationErrors(): boolean {
    const validationFields = [
      this.firstNameValidation,
      this.lastNameValidation,
      this.subjectValidation,
      this.messageValidation,
      this.phoneNumberValidation,
      this.emailValidation
    ];

    return validationFields.some(validationData => validationData.error !== '');
  }

  isFormInvalid(): boolean {
    const hasEmptyValues = Object.values(this.formData).some(value => !value);
    const hasValidationErrors = this.hasValidationErrors();
    return hasEmptyValues || hasValidationErrors;
  }

  resetForm() {
    this.formData = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
    };

    this.firstNameValidation.error = '';
    this.lastNameValidation.error = '';
    this.subjectValidation.error = '';
    this.messageValidation.error = '';
    this.phoneNumberValidation.error = '';
    this.emailValidation.error = '';
  }

  submitForm(event: Event) {
    event.preventDefault();
    this.pagesService.contact(this.formData).subscribe(() => {
        this.resetForm();
    });
  }

}
