<div class="h-100">
  <div class="d-grid col-12 border-bottom p-2">
    <img class="mx-auto d-block cursor-pointer" style="width: 150px;"
      src="/assets/images/logo-main.svg?v={{logoVersionNumber()}}" (click)="back()" alt="Farmbox Delivery - Logo">
  </div>
  <div class="d-grid col-12 text-center mt-4 mb-4">
    <h2 class="fw-bold">Terms & Conditions</h2>
  </div>
  <div class="d-grid container col-12 terms-container-2 p-5 position-relative">
    <div class="w-100 h-100" [innerHTML]="termAndCondition"></div>
  </div>
</div>
