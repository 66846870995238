import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {NgForOf} from "@angular/common";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-why-farmbox',
  standalone: true,
    imports: [
        NgForOf
    ],
  templateUrl: './why-farmbox.component.html',
  styleUrl: './why-farmbox.component.scss'
})
export class WhyFarmboxComponent implements OnInit {
  blogUrl: string = "https://blog.farmboxdelivery.com";
  posts: any[] = [];

  insta = [
    {
      img: 'assets/images/landing/home/instagram/1.png',
    },
    {
      img: 'assets/images/landing/home/instagram/2.png',
    },
    {
      img: 'assets/images/landing/home/instagram/3.png',
    },
    {
      img: 'assets/images/landing/home/instagram/4.png',
    }

  ]

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    //WordPress post
    this.getLatestPosts();
  }


  // WordPress post
  getLatestPosts(): void {
    const apiUrl = this.blogUrl + '/wp-json/wp/v2/posts?per_page=3&orderby=date&order=desc';

    this.http.get<any[]>(apiUrl).subscribe(
      (data) => {
        if (data && Array.isArray(data)) {
          this.posts = data;
          this.getFeaturedImages();
          this.fetchCategoryNames();
        } else {
          console.warn('No valid data fetched');
        }
      },
      (error) => {
        console.error('Error fetching posts:', error);
      }
    );
  }
  // WordPress post img
  getFeaturedImageUrl(mediaId: number): void {
    const mediaUrl = this.blogUrl + `/wp-json/wp/v2/media/${mediaId}`;

    this.http.get<any>(mediaUrl).subscribe(
      (mediaData) => {
        // Add the image URL to the post object
        const imageUrl = mediaData.source_url;
        this.posts.forEach((post) => {
          if (post.featured_media === mediaId) {
            post.featured_image_url = imageUrl;  // Add image URL to the post
          }
        });
      },
      (error) => {
        console.error('Error fetching featured media:', error);
      }
    );
  }

  //WordPress get categories
  fetchCategoryNames(): void {
    this.posts.forEach(post => {
      if (post.categories && post.categories.length > 0) {
        const categoryIds = post.categories.join(',');
        const categoryUrl = `${this.blogUrl}/wp-json/wp/v2/categories?include=${categoryIds}`;

        this.http.get<any[]>(categoryUrl).subscribe(
          (categories) => {
            post.categoriesData = categories.map(cat => ({ id: cat.id, name: cat.name, modifiedId: cat.id % 18 }));
          },
          (error) => {
            console.error('Error fetching categories:', error);
          }
        );
      } else {
        post.categoryNames = [];
      }
    });
  }


  getFeaturedImages(): void {
    this.posts.forEach((post) => {
      if (post.featured_media) {
        this.getFeaturedImageUrl(post.featured_media);  // Fetch image for each post
      }
    });
  }

}
