<div class="page-title" style="background-image: url('assets/images/landing/sustainable/title-bg.webp')">
  <h1 class="h1-semibold head-font">We’re commited to doing better</h1>
</div>

<div class="leaf-banner">
  <div class="container">
    <div class="leaf-left"><img src="assets/images/landing/leaf-1.svg"></div>
    <div class="leaf-center"><img src="assets/images/landing/leaf-2.svg"></div>
    <div class="leaf-right"><img src="assets/images/landing/leaf-3.svg"></div>
    <h3 class="h2-semibold head-font">Recycle your farm boxes</h3>
    <p>Great news! You can leave select packaging out for your driver to pick up for reuse or recycling when we deliver your order. Farmbox Delivery is the only local online grocery that collects packaging for reuse. Just one more way you can grocery shop with the planet in mind.</p>

  </div>
</div>
<section class="page-wrap animated fadeIn">
  <div class="container">
    <h3 class="h2-semibold head-font text-center">Help Us Do Better</h3>
    <div class="how-step-wrap is-col-3 has-animation fadeInUp">
      <div class="how-step">
        <div class="icon">1</div>
        <div class="detail">
          <img src="assets/images/landing/home/how/how-1.png"/>
          <p>Clean your used silver thermal liners, gel packs, egg cartons, and farmboxes</p>
        </div>
      </div>
      <div class="how-step is-alternate">
        <div class="icon">2</div>
        <div class="detail">
          <img src="assets/images/landing/home/how/how-2.png"/>
         <p>The night before your delivery, place them where our drivers normally leave your Farmbox</p>
        </div>
      </div>
      <div class="how-step">
        <div class="icon">3</div>
        <div class="detail">
          <img src="assets/images/landing/home/how/how-4.png"/>
          <p>When we drop off your order, we’ll pick up your silver insulated liners, egg carton liners and gel packs (10 items max).</p>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="banner py-5 bg-primary-subtle text-center">
  <div class="container">
    <h3 class="h2-semibold head-font text-center mb-3">Sustainable, community centered</h3>
    <p>We are committed to positively contributing to the world around us. Being environmentally friendly is a part of who we are. We utilize reusable boxes and bags with each delivery. We also contribute to our community through supporting local food.</p>
    <p>By the way, because we want to make sure nothing goes to wate, you might see updates to packaging happen gradually instead of all at once</p>
  </div>
</div>
<div class="banner py-5 text-center">
  <div class="container">
    <h3 class="h2-semibold head-font text-center mb-3">No veggie left behind</h3>
    <p>Roughly 40% of the food produced in the US goes to wate, and traditional grocery stores only contribute to the problem. We only source what we’re able to sell - and when we run into excess food stock, we donate 100% of it to local food banks like the Community Food Bank of Roanoke, TX.</p>
  </div>
</div>
