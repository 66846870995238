import { Component, OnInit, inject, input, Input, Output, EventEmitter, signal, Inject, PLATFORM_ID, computed } from '@angular/core';
import { ProductsService } from '../../../product/products.service';
import { ProductCardComponent } from '../../../shared/product-card/product-card.component';
import { Location, NgIf, isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from '../../../shared/local-storage.service';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { ResolutionService } from '../../../shared/resolution.service';
import { MobileProductCardComponent } from '../../../shared/mobile-product-card/mobile-product-card.component';

@Component({
  selector: 'app-suggested-products-signup',
  standalone: true,
  imports: [ProductCardComponent, MobileProductCardComponent, MatRadioModule, FormsModule, NgIf],
  templateUrl: './suggested-products-signup.component.html',
  styleUrl: './suggested-products-signup.component.scss'
})
export class SuggestedProductsSignupComponent implements OnInit {
  private productsService = inject(ProductsService);
  private location = inject(Location);
  private localStorageService = inject(LocalStorageService);
  @Input() stepper!: MatStepper;
  @Input() firstStep!: MatStep;
  @Output() onNextStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() formValidityChanged = new EventEmitter<boolean>();
  suggestedProductsSelected: any = signal(false);

  #resolutionService = inject(ResolutionService);
  isMobile = computed(() => this.#resolutionService.isMobile());

  subscriptionCard: any = input();
  selectedCard: any = null;

  suggestedProducts = [
    { id: 1, name: 'Fruits' },
    { id: 2, name: 'Vegetables' },
    { id: 3, name: 'Meat' }
  ];

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.suggestedProductsSelected = signal(this.suggestedProducts[0]);
  }

  selectCard(card: any) {
    this.selectedCard = card;
  }

  public updateProductsSelection(product: any) {
    this.suggestedProductsSelected = signal(product);
  }

  continue() {
    this.formValidityChanged.emit(true)
    this.firstStep.completed = true
    this.onNextStep.emit(this.firstStep);
  }
}
