<div class="container-fluid main-container__full">
  <!-- Breadcrumbs -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#"><span class="material-symbols-outlined">home</span></a>
      </li>
      <li class="breadcrumb-item" aria-current="page"><span class="fw-bold">
         Recipes
        </span></li>
      <li class="breadcrumb-item active" aria-current="page"><span class="fw-bold">
          Carrot Ginger Soup
        </span></li>
    </ol>
  </nav>

  <div class="row">
    <div class="col-md-6">
      <div class="recipe-detail">
        <img src="assets/images/landing/about/title-bg.webp" class="recipe-detail__img">
        <div class="recipe-detail__info">
          <h3 class="recipe-detail__title">Carrot Ginger Soup</h3>
          <p class="recipe-detail__desc">Smooth carrot soup with ginger, orange and onion. So easy and healthy too!</p>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="ingredient">
        <h3 class="ingredient__title">Ingredients</h3>
        <ul class="ul-checklist ">
          <li>3 tablespoons butter</li>
          <li>1 1/2 pounds carrots (6-7 large carrots), peeled and sliced thin</li>
        </ul>
      </div>
    </div>
  </div>

  <h2 class="mt-5">Instructions</h2>
  <ol class="ol-list">
    <li>Sauté onions and carrots: Melt the butter in a soup pot over medium heat and cook the onions and carrots, stirring occasionally, until the onions soften, about 5 to 8 minutes. Do not let the onions or carrots brown. Sprinkle a teaspoon of salt over the carrots and onions as they cook.</li>
    <li>Add stock and water, ginger, and strips of orange zest. Bring to a simmer, cover, and cook until the carrots soften, about 20 minutes.</li>
    <li>Remove the strips of orange zest! It\\\'s easy to forget this step, and if you forget and purée the soup with the strips of zest still in it, the soup may be too bitter for your taste.</li>
  </ol>
</div>
