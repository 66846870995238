<div class="animated fadeIn">
  <h1>Profile</h1>
  @if (isTemporaryRouteChange()){
  <app-empty-message [message]="temporaryRouteChangeMessage()"></app-empty-message>
  }
  <!-- Personal information -->
  <h4 class="my-4 text-body-tertiary fw-bold">Personal information</h4>
  <form action="" [formGroup]="profileForm">
    <div class="row row-gap-3">
      <div class="col-lg-6 col-md-12">
        <div class="form-floating has-validation">
          <input class="form-control" [class.is-invalid]="validateErrors('firstName')" id="firstName" placeholder
            formControlName="firstName">
          <label for="firstName">First name</label>
          <div *ngIf="validateErrors('firstName')" class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{validateErrors('firstName')}}
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="form-floating has-validation">
          <input class="form-control" [class.is-invalid]="validateErrors('lastName')" id="lastName" placeholder
            formControlName="lastName">
          <label for="lastName">Last name</label>
          <div *ngIf="validateErrors('lastName')" class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{validateErrors('lastName')}}
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="form-floating has-validation">
          <input class="form-control " [class.is-invalid]="validateErrors('phone')" id="phone" placeholder
            formControlName="phone" (input)="formatPhoneNumber()">
          <label for="phone">Phone</label>
          <div *ngIf="validateErrors('phone')" class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{validateErrors('phone')}}
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="form-floating has-validation">
          <mat-form-field class="w-100"
            [class.is-invalid]="profileForm.get('birthday')?.hasError('isAtLeast15YearsOld')">
            <mat-label>Birthday</mat-label>
            <input matInput formControlName="birthday"
              [class.is-invalid]="profileForm.get('birthday')?.hasError('isAtLeast15YearsOld')"
              [matDatepicker]="birthdayPicker" (dateChange)="onDateChange($event)">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="birthdayPicker">
            </mat-datepicker-toggle>
            <mat-datepicker #birthdayPicker></mat-datepicker>
          </mat-form-field>
          <div *ngIf="profileForm.get('birthday')?.hasError('isAtLeast15YearsOld')" class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{validateErrors('birthday')}}
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="form-floating">
          <input type="email" class="form-control" id="email" placeholder formControlName="email">
          <label for="email">Email</label>
        </div>
        <a href="javascript:void(0)" class="float-end link-primary" (click)="openModalChangeCredentials(true)">Change
          email</a>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="form-floating">
          <input type="password" class="form-control" id="password" placeholder formControlName="password">
          <label for="password">Password</label>
        </div>
        <a href="javascript:void(0)" class="float-end link-primary" (click)="openModalChangeCredentials()">Reset
          password</a>
      </div>
    </div>
    <!-- Delivery information -->
    <h4 class="my-4 text-body-tertiary fw-bold">Delivery information</h4>
    @if (!isDisableCustomerTypeUpdate()) {
    <b>What kind of delivery are you interested in?</b>
    <mat-radio-group [(ngModel)]="selectedMembership" (ngModelChange)="setUpSelectedMembership()"
      [ngModelOptions]="{standalone: true}">
      <div class="row-cols-2 row row-gap-4 my-3">
        @for (item of membershipList(); track item.id) {
        <section class="col animated fadeIn">
          <div class="card border border-opacity-10">
            <div class="row">
              <section class="col">
                <div class="card-body">
                  <div>
                    <mat-radio-button [value]="item" [checked]="selectedMembership().id === item.id">
                      {{item.name}}
                    </mat-radio-button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        }
      </div>
    </mat-radio-group>
    }

    @if (selectedMembership().id == 0 ? (profileData() && profileData().settings &&
    profileData().settings.requireAddress) : selectedMembership().configuration?.requireAddress) {
    <div action="" class="form" formGroupName="address">
      <div class="row row-gap-3">
        <div class="col-lg-6 col-md-12">
          <div class="form-floating has-validation">
            <input #autocompleteInput (ngModelChange)="changeAddress()" class="form-control"
              [class.is-invalid]="!isLoadingContent() && (!hasDeliveryAddressCoverage() && isGoogleAddressFilled())"
              [class.is-valid]="!isLoadingContent() && hasDeliveryAddressCoverage()" id="address" placeholder
              formControlName="street">
            <label for="address">Address</label>
            @if (isLoadingContent()) {
            <mat-progress-bar mode="indeterminate" value="accent"></mat-progress-bar>
            } @else {
            @if (hasDeliveryAddressCoverage()) {
            <div class="valid-tooltip">
              This address is in our delivery area!
            </div>
            } @else if (isGoogleAddressFilled()) {
            <div class="invalid-tooltip">
              Oops! Your address isn't in our delivery zone.
            </div>
            }
            }
          </div>
          @if (shouldShowAddressDiscardChanges() && !isTemporaryRouteChange()) {
          <a href="javascript:void(0)" class="float-end link-primary" (click)="handleChangeUpdateAddress()">
            {{isUpdatingAddress() ? 'Discard changes' : 'Change address'}}
          </a>
          }
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="form-floating">
            <input class="form-control" id="additionalAddress" placeholder formControlName="additional">
            <label for="additionalAddress">Additional address</label>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="form-floating has-validation">
            <input class="form-control" [class.is-invalid]="validateErrors('city')" id="city" placeholder
              formControlName="city">
            <label for="city">City</label>
            <div *ngIf="validateErrors('address.city')" class="invalid-feedback">
              <i class="fas fa-xmark"></i>
              {{validateErrors('address.city')}}
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="form-floating has-validation">
            <input class="form-control" [class.is-invalid]="validateErrors('state')" id="state" placeholder
              formControlName="state">
            <label for="state">State</label>
            <div *ngIf="validateErrors('address.state')" class="invalid-feedback">
              <i class="fas fa-xmark"></i>
              {{validateErrors('address.state')}}
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="form-floating has-validation">
            <input class="form-control" [class.is-invalid]="validateErrors('state')" id="zipCode" placeholder
              formControlName="zipCode">
            <label for="zipCode">Zip code</label>
            <div *ngIf="validateErrors('address.zipCode')" class="invalid-feedback">
              <i class="fas fa-xmark"></i>
              {{validateErrors('address.zipCode')}}
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-floating">
            <input class="form-control" placeholder id="deliveryNotes" style="height: 100px"
              formControlName="deliveryNotes" />
            <label for="deliveryNotes">Delivery notes</label>
          </div>
        </div>
        <!-- Delivery window information -->
        @if (isUpdatingAddress()) {
        <div class="row mt-5 mb-5 animated fadeInDown">
          <div class="col">
            <h3>When should we deliver your order?</h3>
            <mat-form-field class="w-100" [class.option-selected]="!!selectedDeliveryDay()">
              <mat-label>Choose a day</mat-label>
              <mat-select name="selectedDeliveryDay" [value]="selectedDeliveryDay()?.id || null"
                (selectionChange)="setSelectedDeliveryDay($event)">
                @for (item of deliveryOptions; track $index) {
                <mat-option [value]="item.id">{{item.name}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col d-flex align-items-end">
            @if (selectedDeliveryDay()) {
            @if (selectedDeliveryDay()?.allowTimeWindow) {
            <mat-form-field class="w-100 animated fadeInLeft" [class.option-selected]="!!selectedDeliveryTimeWindow()">
              <mat-label>Choose a time window</mat-label>
              <mat-select name="selectedDeliveryTimeWindow"
                (selectionChange)="selectedDeliveryTimeWindow.set($event.value)">
                @for (item of timeSet(); track $index) {
                <mat-option [value]="item.id">{{item.name}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            } @else {
            <mat-form-field class="w-100 animated fadeInLeft">
              <mat-label>Time window</mat-label>
              <input type="text" matInput readonly="true" name="selectedDeliveryTimeWindow"
                [value]="selectedDeliveryDay()?.timeSet?.[0]?.name">
            </mat-form-field>
            }
            }
          </div>
        </div>
        }
      </div>
    </div>
    } @else {
    @if (pickupLocationList().length > 1) {
    <div class="animated fadeInDown">
      <mat-form-field class="w-100">
        <mat-label>Pickup Address</mat-label>
        <mat-select name="selectedPickUpOption" [value]="selectedPickupLocation().id || null"
          (selectionChange)="setSelectedPickupLocation($event.value)">
          @for (item of pickupLocationList(); track $index) {
          <mat-option [value]="item.id">{{ item.composedName }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    } @else {
    <div class="form-floating w-100">
      <input type="text" class="form-control" id="pickupLocation" name="pickUpLocation" readonly
        [value]="selectedPickupLocation().composedName || ''" placeholder>
      <label for="address">Pickup address</label>
    </div>
    }

    <div class="row form-row animated fadeInDown my-3">
      <div class="col-md-6">
        <mat-form-field class="w-100">
          <mat-label>Choose a day</mat-label>
          <mat-select name="selectedDeliveryDay" [value]="selectedPickupDeliveryDay()?.id || null"
            (selectionChange)="setSelectedPickupDeliveryDay($event.value)">
            @for (item of pickupDeliveryDaysList(); track item.id) {
            <mat-option [value]="item.id">{{ item.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6 d-flex align-items-end">
        @if (selectedPickupDeliveryDay()) {
        <div class="form-floating w-100">
          <input type="text" class="form-control" id="pickupLocationTimeWindow" name="pickupLocationTimeWindow" readonly
            [value]="selectedPickupDeliveryDay()?.deliveryWindow" placeholder>
          <label for="address">Delivery time window</label>
        </div>
        }
      </div>
    </div>
    }

    <!-- Dietary information -->
    <h4 class="mt-4 text-body-tertiary fw-bold">Dietary information</h4>
    <div action="" class="form" formGroupName="dietaryInformation">
      <div class="row gap-3">
        <div class="col-12 mb-2 pt-3">
          <mat-slide-toggle formControlName="hasRestriction">I have dietary restrictions. I only want these
            kind of products</mat-slide-toggle>
          @if(showDietaryRestrictions()) {
          <div class="check-list animated fadeIn">
            @for (item of dietaryPreferences(); track $index) {
            <mat-checkbox class="col" [checked]="item.completed"
              (change)="handleDietaryPreferencesChange($event, item)">{{item.name}}</mat-checkbox>
            }
          </div>
          }
        </div>
      </div>
    </div>

    <div class="row form-row" action="" class="form" formGroupName="settings">
      <div class="col-md-3">
        <div class="form-floating">
          <mat-checkbox formControlName="receiveText">Receive SMS messages</mat-checkbox>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-floating">
          <mat-checkbox formControlName="receiveTextDelivery">Receive SMS Delivery Notifications</mat-checkbox>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <mat-checkbox formControlName="receiveNewsletter">Receive information on our latest product offerings, events
            and local impact</mat-checkbox>
        </div>
      </div>
    </div>


    <div class="d-grid col-lg-3 col-md-12 mx-auto mt-5">
      <button type="button" class="btn btn-primary" (click)="saveChanges($event)"
        [disabled]="disableSubmitButton()">{{submitButtonText()}}</button>
    </div>

  </form>

</div>