import { Component, AfterViewInit, inject, signal, WritableSignal, computed, Inject, PLATFORM_ID, Input, Output, EventEmitter, input, model } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStep, MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { environment } from '../../../../environments/environment';
import { Loader } from '@googlemaps/js-api-loader';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DeliveryAddress, PersonalInformation } from '../signup.types';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AccountService } from '../../../shared/account.service';
import { RouterLink } from '@angular/router';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS } from '../../../shared/constants/date-formtats';
import { PeopleShoppingFor, Reference } from '../../../shared/types/account.types';
import { PaymentMethodService } from '../../../settings/billing/payment-method/payment-method.service';
import { MatRadioModule } from '@angular/material/radio';
import { OnlyNumberDirective } from '../../../shared/directives/only-number.directive';

@Component({
  selector: 'app-personal-information-signup',
  standalone: true,
  providers: [provideNativeDateAdapter(),
  {
    provide: Loader,
    useValue: new Loader({
      apiKey: environment.apis.google.places,
      libraries: ['places'],
      region: 'US',
    })
  },
  { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS.MONTH_DAY_YEAR },
    PaymentMethodService
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIcon,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    RouterLink,
    MatRadioModule,
    OnlyNumberDirective
  ],
  templateUrl: './personal-information-signup.component.html',
  styleUrl: './personal-information-signup.component.scss'
})
export class PersonalInformationSignupComponent implements AfterViewInit {
  accountService = inject(AccountService);

  deliveryOption = [
    'Home Delivery',
    'Pick Up',
    'EBT/Snap'
  ];

  deliveryOptionSelected = signal(this.deliveryOption[0]);

  @Input() stepper!: MatStepper;
  @Input() firstStep!: MatStep;
  @Input() peopleOptions: PeopleShoppingFor[] | undefined;
  @Input() dietaryRestrictions: string | any[] | undefined;
  @Output() onNextStep: EventEmitter<any> = new EventEmitter<any>()
  @Output() formValidityChanged = new EventEmitter<boolean>();
  @Output() personalInformationChanged = new EventEmitter<WritableSignal<PersonalInformation | null>>();
  @Output() dietaryChanged = new EventEmitter<any>();
  isLinear = true;

  deliveryAddress: WritableSignal<Partial<DeliveryAddress>> = signal({
    additionalAddress: '',
    address: '',
    city: '',
    deliveryNotes: '',
    stateCode: '',
    zipCode: '',
  })

  personalInformation: WritableSignal<PersonalInformation | null> = signal(null)

  referralSources = input.required<Reference[]>();
  referralSource = model(null);
  selectedReferralSourceInfo = computed<Reference | null>(() => {
    const info = this.referralSources().find(r => r.id === this.referralSource());
    return info || null
  });

  hidePassword = true;
  isPasswordValid1 = computed(() => !!(this.passwordConditions.every(condition => condition.valid())));
  isValidFormPersonalInformation = computed(() => this.validatePersonalInfoForm());
  isValidFormShoppingPreferences = computed(() => this.validateShoppingInfoForm());
  personalInformationValidations = new Map<string, WritableSignal<{ valid: boolean, error: string, validations: string[] }>>();

  paymentMethodValidations = new Map<string, WritableSignal<{ valid: boolean, error: string, validations: string[] }>>();


  isMinLengthValid = computed(() => (this.personalInformation()?.password ?? '').length >= 8)
  hasUpperCase = computed(() => /[A-Z]/.test(this.personalInformation()?.password || ''))
  hasLowerCase = computed(() => /[a-z]/.test(this.personalInformation()?.password || ''))
  hasSpecialChar = computed(() => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(this.personalInformation()?.password || ''))
  hasNumber = computed(() => /\d/.test(this.personalInformation()?.password || ''))
  hasNotEmptySpaces = computed(() => !((this.personalInformation()?.password || '').includes(' ')))
  passwordConditions = [
    { label: 'At least 8 Characters', valid: () => this.isMinLengthValid() },
    { label: 'At least 1 Uppercase (A-Z)', valid: () => this.hasUpperCase() },
    { label: 'At least 1 Lowercase (a-z)', valid: () => this.hasLowerCase() },
    { label: 'At least 1 Symbol', valid: () => this.hasSpecialChar() },
    { label: 'At least 1 Number (0-9)', valid: () => this.hasNumber() },
    { label: 'Do not include spaces', valid: () => this.hasNotEmptySpaces() },
  ];

  shouldSetUpSettings: boolean = true;
  birthday: Date = new Date();
  birthdayValid: boolean = true;
  checkboxValues: boolean[] = [true, true, false];

  constructor(@Inject(PLATFORM_ID) private platformId: any) {

  }

  ngAfterViewInit() {
    if (!isPlatformBrowser(this.platformId)) return;
    this.setUpPersonalInfoValidations();
  }

  updatePersonalInformation(newValue: any, formValidationKey: string, isContactPerson?: boolean) {

    if (isContactPerson) {
      this.personalInformation.update((value: any) => ({ ...value, contactPerson: { ...value.contactPerson, ...newValue } }));
    } else {
      this.personalInformation.update(value => ({ ...value, ...newValue }));
    }

    if (formValidationKey === 'referralSource') {
      this.personalInformation.update(value => {
        if (value)
          value.additionalInfo = '';
        return value;
      });
      this.personalInformationValidations.get('additionalInfo')?.set({ valid: true, error: '', validations: ['required'] });
    }

    // Validate form fields:
    const key = Object.keys(newValue)[0];
    const value = newValue[key];
    this.validatePersonalInfoField(formValidationKey, value);
  }

  private validatePersonalInfoForm() {
    const personalInformation = this.personalInformation();

    const formFilled =
      personalInformation?.firstName &&
      personalInformation?.lastName &&
      personalInformation?.phone &&
      personalInformation?.email &&
      this.isPasswordValid1() &&
      this.selectedReferralSourceInfo() &&
      (
        !this.selectedReferralSourceInfo()?.hasAdditionalInfo ||
        (
          this.selectedReferralSourceInfo()?.hasAdditionalInfo &&
          this.personalInformation()?.additionalInfo
        )
      );

    if (!formFilled) return false;

    const validations = Array.from(this.personalInformationValidations.values());
    const valid = validations.every((validation) => validation()?.valid);
    this.formValidityChanged.emit(valid);
    return valid;
  }

  private validateShoppingInfoForm() {
    let valid;

    const formFilled = !!(this.personalInformation()?.shopfor !== null)
    if (this.personalInformation()?.shopfor && formFilled) {
      valid = true;
    }

    return valid;
  }

  continue() {

    if (!this.validatePersonalInfoForm())
      return;

    this.firstStep.completed = true
    this.personalInformationChanged.emit(this.personalInformation);
    this.dietaryChanged.emit(this.dietaryRestrictions);
    this.onNextStep.emit(this.firstStep);
  }

  private setUpPersonalInfoValidations() {
    const defaultValue = { valid: true, error: '', validations: ['required'] };
    this.personalInformationValidations.set('firstName', signal(defaultValue));
    this.personalInformationValidations.set('lastName', signal(defaultValue));
    this.personalInformationValidations.set('phone', signal({ ...defaultValue, validations: [...defaultValue.validations, 'phone'] }));
    this.personalInformationValidations.set('email', signal({ ...defaultValue, validations: [...defaultValue.validations, 'email'] }));
    this.personalInformationValidations.set('password', signal({ ...defaultValue, validations: [...defaultValue.validations, 'password'] }));
    this.personalInformationValidations.set('referralSource', signal(defaultValue));
    this.personalInformationValidations.set('additionalInfo', signal(defaultValue));
    this.personalInformationValidations.set('advertisement', signal(defaultValue));
    this.personalInformationValidations.set('contactFirstName', signal(defaultValue));
    this.personalInformationValidations.set('contactLastName', signal(defaultValue));
    this.personalInformationValidations.set('contactEmail', signal({ ...defaultValue, validations: [...defaultValue.validations, 'email'] }));
    this.personalInformationValidations.set('contactPhone', signal({ ...defaultValue, validations: [...defaultValue.validations, 'phone'] }));
  }

  validatePersonalInfoField(key: string, value: string | any) {

    const entry = this.personalInformationValidations.get(key);
    if (!entry) return;

    if (typeof value !== 'string' && ![null, undefined].includes(value.target?.value))
      value = value.target.value;

    value = value.toString();

    const data = entry();
    for (const validation of entry().validations) {
      switch (validation) {
        case 'required':
          data.error = !value.trim() ? 'This field is required' : ''
          break;
        case 'phone':
          data.error = !(/^\d+$/.test(value)) || value.trim().length !== 10 ? 'Enter a valid phone number' : ''
          break;
        case 'email':
          data.error = !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,6}$/.test(value)) ? 'Enter a valid email' : ''
          break;
        case 'password':
          data.error = !this.isPasswordValid1() ? 'Enter a valid password' : ''
          break;
        default:
          break;
      }
    }

    this.personalInformationValidations.set(key, signal({ valid: !data.error, error: data.error, validations: data.validations }));
  }

  openNewTab(url: string) {
    window.open(url, '_blank');
  }

  updateCheckboxValue(checked: boolean, index: number) {
    this.checkboxValues[index] = checked;
  }

  onDateChange(event: any) {
    const birthday: moment.Moment = event.value;
    this.birthday = birthday.toDate();
    const formattedBirthday: string = birthday.format('YYYY-MM-DD');
    this.updatePersonalInformation({ birthday: formattedBirthday }, 'birthday');

    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 15);

    if (this.birthday < currentDate) {
      this.birthdayValid = true;
    } else {
      this.birthdayValid = false;
    }
  }
}
