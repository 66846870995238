import { Component, computed, inject } from '@angular/core';
import { SignalsStoreService } from '../signals-store.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { SidebarService } from './sidebar.service';
import { SidebarCheckList } from './sidebar.types';
import { ShopComponent } from '../../shop/shop.component';
import { CategorySelected } from '../types/common.types';
import { toObservable } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [MatCheckboxModule, FormsModule, ShopComponent, CommonModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {

  //#region Properties

  sidebarService = inject(SidebarService);

  signalsStore = inject(SignalsStoreService);

  hasGlobalMessages = computed(() => !!this.signalsStore.globalMessages().length);

  //#endregion

  //#region Constructor

  constructor(public shopComponent: ShopComponent) {

    toObservable(this.signalsStore.categoryInViewPort)
      .subscribe(obs => this.#changeCategoryInViewPort(obs));
  }

  //#endregion

  //#region Methods

  #changeCategoryInViewPort(obj: CategorySelected | null) {

    const { categoryId, subCategoryId } = obj ?? {};

    if (!categoryId || !subCategoryId)
      return;

    // Search the father
    const indexFather = this.sidebarService
      .sidebar()
      .findIndex(x => x.id === categoryId);

    if (indexFather === -1)
      this.#selectCategory(subCategoryId);
    else
      this.#selectSubCategory(indexFather, subCategoryId);
  }

  #selectCategory(subCategoryId: number) {

    const category = this.sidebarService
      .sidebar()
      .find(x => x.id === subCategoryId);

    if (!category)
      return;

    this.#resetOpenDetails();
    category.checked = true;
  }

  #selectSubCategory(indexFather: number, subCategoryId: number) {

    this.#resetOpenDetails();

    // Mark the father as checked

    this.sidebarService
      .sidebar()[indexFather]
      .checked = true;

    const child = this.sidebarService
      .sidebar()[indexFather]
      .sublist?.find(x => x.id === subCategoryId);

    if (child)
      child.checked = true;
  }

  #resetOpenDetails() {

    this.sidebarService
      .sidebar()
      .forEach(x => {
        x.checked = false;
        x.sublist?.forEach(y => y.checked = false);
      })
  }

  goToSection(item: SidebarCheckList, event?: Event) {

    if (event && item.checked && item.sublist?.length)
      event.preventDefault();

    const element = document.getElementById(item.key as string);

    if (element)
      element?.scrollIntoView(true);
    else if (item.sublist && item.sublist.length)
      document.getElementById(item.sublist[0].key as string)?.scrollIntoView(true);
  }

  //#endregion
}
