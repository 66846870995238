@defer (on viewport) {
<article class="blog">
  <img class="blog_img" [src]="blog().image || 'assets/images/product-placeholder.webp'" alt="Image of {{blog().title}}"
    (error)="handleImageError($event)" />
  <header class="blog-category">
    <span class="category" [ngStyle]="{'background-color': blog().category.color}">
      {{blog().category.name}}
    </span>
  </header>
  <div class="blog_detail">
    <div class="blog_title">{{blog().title}}</div>
    <div class="blog_desc">{{blog().teaser}}</div>
  </div>
  <footer class="blog_btn">
    <button type="button" class="btn btn-primary" (click)="goToDetail()">
      Read more
    </button>
  </footer>
</article>
} @placeholder (minimum 300ms) {
<div class="blog placeholder-glow">
  <div class="blog_img placeholder bg-secondary"></div>
  <div class="blog-category placeholder-glow">
    <span class="category placeholder bg-secondary col-2"></span>
    <span class="category placeholder bg-secondary col-2"></span>
    <span class="category placeholder bg-secondary col-2"></span>
  </div>
  <div class="blog_detail placeholder-glow">
    <div class="blog_title placeholder bg-secondary col-12 h-75"></div>
    <div class="blog_desc placeholder bg-secondary col-10 h-50 mt-2"></div>
    <div class="blog_desc placeholder bg-secondary col-9 h-50 mt-1"></div>
  </div>
  <div class="blog_btn">
    <div class="btn placeholder bg-secondary col-4"></div>
  </div>
</div>
}