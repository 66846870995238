import { inject, Injectable, signal } from '@angular/core';
import { RequestHandlerParams } from '../../shared/types/api-service.types';
import { ApiService } from '../../shared/api.service';
import { ApiResponse } from '../../shared/common/types';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  #apiService = inject(ApiService);

  #endpoints = {
    blogPost: '/blog-posts',
    categories: '/blog-post-categories'
  };

  page = signal(0);

  categoryId = signal(0);

  size = signal(10);

  constructor() { }

  getAll() {

    let query = `?page=${this.page()}&len=${this.size()}`;

    if (this.categoryId())
      query += `&categoryId=${this.categoryId()}`;

    const params: RequestHandlerParams = {
      endpoint: `${this.#endpoints.blogPost}${query}`,
      method: 'GET',
      apiV3: true
    };

    return this.#apiService.handleRequest<ApiResponse<any>>(params);
  }

  getById(url: string) {

    const params: RequestHandlerParams = {
      endpoint: `${this.#endpoints.blogPost}/${url}`,
      method: 'GET',
      apiV3: true
    };

    return this.#apiService.handleRequest<ApiResponse<any>>(params);
  }

  getCategories() {

    const params: RequestHandlerParams = {
      endpoint: this.#endpoints.categories,
      method: 'GET',
      apiV3: true
    };

    return this.#apiService.handleRequest<ApiResponse<any>>(params);
  }
}
