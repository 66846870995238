import { Injectable, inject } from '@angular/core';

import { filter, tap } from 'rxjs';
import { RequestHandlerParams } from './types/api-service.types';
import { ApiService } from './api.service';
import { NotificationService } from './notification/notification.service';
import { ApiResponse } from './common/types';
import { TipsAndDonationsPayload } from './types/order.type';
import { ModalContentTypes } from './constants/modal-content-types';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root',
})
export class TipsDonationsService {
  private apiService = inject(ApiService);
  private notificationService = inject(NotificationService);
  private orderService = inject(OrderService);

  delete(filterParams: number, donationOrTip: string) {
    const type =
      donationOrTip === ModalContentTypes.DONATION ? 'donations' : 'tips';
    const params: RequestHandlerParams = {
      method: 'DELETE',
      endpoint: `/order/${type}?applyToAllOrders=${filterParams}`,
      apiV3: true,
    };
    return this.apiService
      .handleRequest<ApiResponse<any>>(params)
      .pipe(
        filter((response) => !!response?.data),
        tap((response) => {
          this.notificationService.show({
            text: response.message,
            type: 'success',
          });
          this.orderService.getOrder();
          const subKey = donationOrTip === ModalContentTypes.DONATION ? 'donation' : 'tip';

          this.orderService.editSubKey(
            {
              subKeyPath: `orderProducts.paymentDetails.${subKey}`,
              newValue: null
            }, false
          )
        })
      )
      .subscribe();
  }

  update(body: TipsAndDonationsPayload, donationOrTip: string) {
    const type =
      donationOrTip === ModalContentTypes.DONATION ? 'donations' : 'tips';
    const params: RequestHandlerParams = {
      method: 'PUT',
      endpoint: `/order/${type}`,
      apiV3: true,
      body,
    };

    return this.apiService
      .handleRequest<ApiResponse<any>>(params)
      .pipe(
        filter((response) => !!response?.data),
        tap((response) => {
          this.notificationService.show({
            text: response.message,
            type: 'success',
          });
          this.orderService.getOrder();

          const subKey = donationOrTip === ModalContentTypes.DONATION ? 'donation' : 'tip';

          this.orderService.editSubKey(
            {
              subKeyPath: `orderProducts.paymentDetails.${subKey}`,
              newValue: body
            }, false
          )
        })
      )
      .subscribe();
  }

  create(body: TipsAndDonationsPayload, donationOrTip: string) {
    const type =
      donationOrTip === ModalContentTypes.DONATION ? 'donations' : 'tips';
    const params: RequestHandlerParams = {
      method: 'POST',
      endpoint: `/order/${type}`,
      apiV3: true,
      body,
    };

    return this.apiService
      .handleRequest<ApiResponse<any>>(params)
      .pipe(
        filter((response) => !!response?.data),
        tap((response) => {
          this.notificationService.show({
            text: response.message,
            type: 'success',
          });
          this.orderService.getOrder();

          const subKey = donationOrTip === ModalContentTypes.DONATION ? 'donation' : 'tip';

          this.orderService.editSubKey(
            {
              subKeyPath: `orderProducts.paymentDetails.${subKey}`,
              newValue: body
            }, false
          )
        })
      )
      .subscribe();
  }
}
