import { Component } from '@angular/core';

@Component({
  selector: 'app-delivery-areas',
  standalone: true,
  imports: [],
  templateUrl: './delivery-area.component.html',
  styleUrl: './delivery-area.component.scss'
})
export class DeliveryAreaComponent {

}
