<div class="layout-recipe">
  <div class="layout-recipe__sidebar">
    <h6 class="h6-bold">All recipes</h6>
    <ul class="recipe-nav">
      <li class="active"><a>All</a></li>
      <li><a>Asparagus</a></li>
      <li><a>Avocado</a></li>
      <li><a>Basil</a></li>
      <li><a>Beef</a></li>
    </ul>
  </div>
  <div class="layout-recipe__main">
    <div class="recipe">
      <img src="assets/images/landing/about/title-bg.webp" class="recipe__img">
      <div class="recipe__detail">
        <h3 class="recipe__title">Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling</h3>
        <p class="recipe__desc">Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling</p>
        <button class="btn btn-outline-dark">Check recipe</button>
      </div>
    </div>
    <div class="recipe">
      <img src="assets/images/landing/about/title-bg.webp" class="recipe__img">
      <div class="recipe__detail">
        <h3 class="recipe__title">Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling</h3>
        <p class="recipe__desc">Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling Baked Apples With Oatmeal Filling</p>
        <button class="btn btn-outline-dark">Check recipe</button>
      </div>
    </div>
  </div>
</div>
