<div class="cart-preview-card animated fadeInRight">
  <div class="cart-preview-card__img">
    <a [routerLink]="['/shop/'+(product()?.productUrl || '')]">
      <img [src]="product()?.img || 'assets/images/product-placeholder.webp'" alt="image"
        (error)="handleImageError($event)" />
    </a>
  </div>
  <div class="cart-preview-card__content">
    <a class="title w-100" [routerLink]="['/shop/'+(product()?.productUrl || '')]" [ngbTooltip]="product().name"
      tooltipClass="tooltip-sm">
      {{ product().name }}
    </a>
    @if(allowModification()) {
    <small class="price">{{ (product()?.bundle?.items?.length ? product()?.totalPrice : product().price) | currency
      }}</small>
    }

    @if (!isJustAddedProductNotification()) {
    <div class="cart-preview-card__action">
      <div>
        <div class="input-plus-minus">
          @if(allowModification()) {
          <button type="button" class="btn-plusminus" (click)="handleMinus()" ngbTooltip="Remove Quantity"
            placement="bottom" tooltipClass="tooltip-sm" [disabled]="newQuantity() <= 1">-</button>
          }
          <mat-form-field class="d-flex" subscriptSizing="dynamic">
            <input type="text" matInput appNumberRestriction [minNumber]="1" [maxNumber]="100" (keyup)="onKeyUp($event)"
              (keyup.enter)="updateQuantity()" (blur)="updateQuantity()" [disabled]="!allowModification()"
              [value]="newQuantity()">
          </mat-form-field>
          @if(allowModification()) {
          <button type="button" class="btn-plusminus" (click)="handlePlus()" ngbTooltip="Add Quantity"
            placement="bottom" tooltipClass="tooltip-sm">+</button>
          }
        </div>
      </div>
      @if (allowModification() && !product().isFromSignup) {
      <div>
        <button type="button" (click)="removeCartProduct()" class="cart-delete"><span
            class="material-symbols-outlined">close</span></button>
      </div>
      }
    </div>
    }
  </div>
</div>
