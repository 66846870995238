<div class="h-100 cart-preview-page-container">
  <div class="offcanvas-header">
    <h3>Your added products</h3>
  </div>
  <div class="pt-1 animated fadeInDown ">
    <button class="btn btn-secondary btn-sm d-block w-100" [routerLink]="['/order']">
      Go to Checkout
    </button>
  </div>


  <hr class="m-0 animated fadeInRight border border-0 border-bottom">

  <span class="h7-bold text-center">
    Subtotal: {{subTotal().toFixed(2) | currency}}
  </span>

  <hr class="m-0 animated fadeInRight border border-0 border-bottom">

  @if(groupedProducts()) {
  <div class="p-0 d-flex justify-content-start animated fadeInDown">
    <small class="text-black-50 pt-0">Not Yet Saved</small>
  </div>
  <!-- Firebase "subscription" products -->
  @for (product of groupedProducts()?.subscription; track product.id) {
  <app-product-cart-preview [product]="product" productKey="subscription"></app-product-cart-preview>
  }
  <!-- Firebase "a la carte" products -->
  @for (product of groupedProducts()?.common; track product.id) {
  <app-product-cart-preview [product]="product" productKey="common"></app-product-cart-preview>
  }
  }

  @if (odooOrderProducts()) {
  @if (groupedProducts()) {
  <hr>
  }

  <div class="p-0 d-flex flex-column gap-1 justify-content-start animated fadeInDown">
    <small class="text-black-50 fw-light pt-0">
      In Your Order
    </small>
  </div>
  <!-- Odoo "subscription" products -->
  @for (product of odooOrderProducts()?.subscription; track $index) {
  <app-product-cart-preview [allowModification]="false" [product]="product"
    productKey="subscription"></app-product-cart-preview>
  }
  <!-- Odoo "a la carte" products -->
  @for (product of odooOrderProducts()?.common; track $index) {
  <app-product-cart-preview [allowModification]="false" [product]="product"
    productKey="subscription"></app-product-cart-preview>
  }
  }

  @if (carouselFavoritesProducts()?.length) {
  <div class="product-highlight">
    <h3 class="product-highlight__title">Your favorites</h3>
    <div class="product-highlight__body">
      <app-carousel [items]="carouselFavoritesProducts()"></app-carousel>
    </div>
  </div>
  }
  @if (carouselBuyAgainProducts()?.length) {
  <div class="product-highlight">
    <h3 class="product-highlight__title">Buy again</h3>
    <div class="product-highlight__body">
      <app-carousel [items]="carouselBuyAgainProducts()"></app-carousel>
    </div>
  </div>
  }
  @if (carouselSuggestedProducts()?.length) {
  <div class="product-highlight">
    <h3 class="product-highlight__title">Other people also added</h3>
    <div class="product-highlight__body">
      <app-carousel [items]="carouselSuggestedProducts()"></app-carousel>
    </div>
  </div>
  }
</div>
