import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, computed, inject, input, output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStep, MatStepper, MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterLink } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { isPlatformBrowser } from '@angular/common';
import { ProductCardComponent } from '../../../shared/product-card/product-card.component';
import { MobileProductCardComponent } from '../../../shared/mobile-product-card/mobile-product-card.component';
import { ResolutionService } from '../../../shared/resolution.service';

@Component({
  selector: 'app-farmbox-choosing-signup',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIcon,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    RouterLink,
    MatRadioModule,
    ProductCardComponent,
    MobileProductCardComponent
  ],
  templateUrl: './farmbox-choosing-signup.component.html',
  styleUrl: './farmbox-choosing-signup.component.scss'
})
export class FarmboxChoosingSignupComponent implements OnInit {
  @Input() stepper!: MatStepper;
  @Input() firstStep!: MatStep;
  @Output() onNextStep: EventEmitter<any> = new EventEmitter<any>();
  farmboxCardSubscription: any

  subscriptionCard: any = input();
  selectedCard: any = null;

  #resolutionService = inject(ResolutionService);
  isMobile = computed(() => this.#resolutionService.isMobile());

  outProductSelected = output<boolean>();

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
  }

  selectCard(card: any) {
    this.selectedCard = card;

    if (this.farmboxCardSubscription) {
      this.outProductSelected.emit(true);
      setTimeout(() => {
        this.firstStep.completed = true
        this.onNextStep.emit(this.firstStep);
      }, 300);
    }
  }

  selectedFarmBox($event: any, product: any) {
    this.farmboxCardSubscription = $event;
    this.selectCard(product);
  }
}
