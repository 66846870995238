import { Component, OnInit, Signal, computed, inject } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ProductCartPreview } from "../shared/product-card/product-cart-preview/product-cart-preview.component";
import { SignalsStoreService } from '../shared/signals-store.service';
import { CarouselComponent } from '../shared/carousel/carousel.component';
import { RELATED_ORDER_PRODUCTS } from '../shared/types/order.type';
import { OrderService } from '../shared/order.service';
import { ResolutionService } from '../shared/resolution.service';

@Component({
  selector: 'app-cart-preview',
  standalone: true,
  templateUrl: './cart-preview.component.html',
  styleUrl: './cart-preview.component.scss',
  imports: [
    RouterLink,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    CommonModule,
    ProductCartPreview,
    CarouselComponent
  ]
})
export class CartPreviewComponent implements OnInit {
  private signalsStoreService = inject(SignalsStoreService);
  private orderService = inject(OrderService);
  private resolutionService = inject(ResolutionService);

  groupedProducts: Signal<any> = computed(() => this.groupProducts());
  odooOrderProducts: Signal<any> = computed(() => this.setUpOdooProducts());
  showCartPreview = this.signalsStoreService.showCartPreview;

  isContentLoaded: Signal<any> = computed(() => this.signalsStoreService.isContentLoaded());
  odooOrder: Signal<any | null> = computed(() => this.orderService.odooOrder());
  products: Signal<any | null> = computed(() => this.setUpProducts());
  firebaseOrder: Signal<any | null> = computed(() => this.signalsStoreService.firebaseOrder());
  isMobile = computed(() => this.resolutionService.isMobile());
  subTotal: Signal<number> = computed(() => {
    const commonProducts = [...this.groupedProducts()?.common || [], ...this.odooOrderProducts()?.common || []];
    const subscriptionProducts = [...this.groupedProducts()?.subscription || [], ...this.odooOrderProducts()?.subscription || []];
    return +this.orderService.getSubTotal({ common: commonProducts, subscription: subscriptionProducts }) || 0
  });

  carouselBuyAgainProducts: Signal<any | undefined> = computed(() => this.setUpCarouselItems(RELATED_ORDER_PRODUCTS.BUY_AGAIN, false));
  carouselFavoritesProducts: Signal<any | undefined> = computed(() => this.setUpCarouselItems(RELATED_ORDER_PRODUCTS.FAVORITES, false));
  carouselSuggestedProducts: Signal<any | undefined> = computed(() => this.setUpCarouselItems(RELATED_ORDER_PRODUCTS.SUGGESTED));

  ngOnInit(): void {
    if (this.isMobile())
      this.orderService.getOrder();
  }

  setUpOdooProducts() {
    const odooOrder = this.odooOrder();
    const firebaseOrder = this.firebaseOrder();
    if (!odooOrder || !odooOrder?.products)
      return null

    if (!odooOrder?.products.common?.length && !odooOrder?.products.subscription?.length)
      return null

    let odooCommonProducts: any[] = odooOrder?.products.common;
    let firebaseCommonProducts: any[] = firebaseOrder?.products?.common || [];
    if (odooCommonProducts.length && firebaseCommonProducts.length) {
      const firebaseCommonProductsVariantIds = firebaseCommonProducts.map(e => e.variantId);
      odooCommonProducts = odooCommonProducts.filter(p => !firebaseCommonProductsVariantIds.includes(p.variantId))
    }

    let odooSubscriptionProducts: any[] = odooOrder?.products.subscription;
    let firebaseSubscriptionProducts: any[] = firebaseOrder?.products?.subscription || [];
    if (odooSubscriptionProducts.length && firebaseSubscriptionProducts.length) {
      const firebaseSubscriptionProductsVariantIds = firebaseSubscriptionProducts.map(e => e.variantId);
      odooSubscriptionProducts = odooSubscriptionProducts.filter(p => !firebaseSubscriptionProductsVariantIds.includes(p.variantId))
    }

    return { common: (odooCommonProducts || []).sort((a: any, b: any) => (b.updatedAt || 0) - (a.updatedAt || 0)), subscription: (odooSubscriptionProducts || []).sort((a: any, b: any) => (b.updatedAt || 0) - (a.updatedAt || 0)) };
  }

  groupProducts() {
    const firebaseOrder = this.firebaseOrder();

    if (!firebaseOrder || !firebaseOrder?.products)
      return null

    if (!firebaseOrder?.products.common?.length && !firebaseOrder?.products.subscription?.length)
      return null

    const common = (firebaseOrder?.products?.common || []).sort((a: any, b: any) => (b.updatedAt || 0) - (a.updatedAt || 0))
    const subscription = (firebaseOrder?.products?.subscription || []).sort((a: any, b: any) => (b.updatedAt || 0) - (a.updatedAt || 0))

    return { common, subscription };
  }


  hideShowCartPreview() {
    this.showCartPreview.set(false);
  }


  private setUpProducts(): any {
    const odooOrder = this.odooOrder();
    const firebaseOrder = this.firebaseOrder();

    const { common: odooCommonProducts = [], subscription: odooSubscriptionProducts = [] } = odooOrder?.products || {};
    const { common: fbCommonProducts = [], subscription: fbSubscriptionProducts = [] } = firebaseOrder?.products || {};

    const commonProducts = [...fbCommonProducts, ...odooCommonProducts];
    const subscriptionProducts = [...fbSubscriptionProducts, ...odooSubscriptionProducts]

    return {
      common: commonProducts,
      subscription: subscriptionProducts,
    }
  }

  private setUpCarouselItems(orderProductType: string, isOrderSuggestedProduct: boolean = true) {
    const hiddenProducts: number[] = this.signalsStoreService.hiddenCarouselProducts();

    if (this.odooOrder()?.isSkipped || !this.isMobile()) return [];
    const selectedOrderProductType = Object.values(RELATED_ORDER_PRODUCTS).includes(orderProductType as RELATED_ORDER_PRODUCTS) ? orderProductType
      : RELATED_ORDER_PRODUCTS.SUGGESTED;

    if (!this.isContentLoaded || !this.odooOrder()?.relatedProducts?.[selectedOrderProductType] || !this.odooOrder()?.relatedProducts?.[selectedOrderProductType]?.length)
      if (!this.odooOrder()?.relatedProducts?.[selectedOrderProductType] || !this.odooOrder()?.relatedProducts?.[selectedOrderProductType]?.length)
        return []

    let items = this.odooOrder()?.relatedProducts?.[selectedOrderProductType];

    if (isOrderSuggestedProduct) {
      const allProductsId = [...this.products().common, ...this.products().subscription].map((product: any) => product.id)
      items = items.filter((product: any) => !allProductsId.includes(product.id));
    }

    if (hiddenProducts.length)
      items = items.filter((product: any) => hiddenProducts.indexOf(product.variantId) === -1)

    items = items.map((product: any) => {
      product.image = 'assets/images/logo-main.svg';
      product.name = product.name;
      if (typeof product?.price === 'number')
        product.price = product.price.toFixed(2);

      return ({
        content: {
          orderId: this.odooOrder()?.id,
          product: product,
          settings: { isCardInOrderPage: true, hideWhenWasOOS: true }
        },
      })
    })

    return items
  }
}
