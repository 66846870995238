import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { ProductsService } from '../product/products.service';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Product } from '../product/product.types';
import { ProductCardComponent } from '../shared/product-card/product-card.component';
import { SkeletonProductCardComponent } from '../shared/skeleton-product-card/skeleton-product-card.component';
import { FavOrDislike } from '../shared/product-card/product-card.types';
import { EmptyMessageComponent } from '../shared/empty-message/empty-message.component';
import e from 'express';
import { ResolutionService } from '../shared/resolution.service';
import { MobileProductCardComponent } from '../shared/mobile-product-card/mobile-product-card.component';

@Component({
  selector: 'app-fav-products',
  standalone: true,
  imports: [
    ProductCardComponent,
    MobileProductCardComponent,
    SkeletonProductCardComponent,
    EmptyMessageComponent,
    CommonModule,
    RouterLink
  ],
  templateUrl: './fav-products.components.html'
})
export class FavProductsComponent implements OnInit {

  //#region Services

  #productService = inject(ProductsService);

  #resolutionService = inject(ResolutionService);

  //#endregion

  //#region Properties

  isMobile = computed(() => this.#resolutionService.isMobile());

  likes = signal<Product[]>([]);

  dislikes = signal<Product[]>([]);

  prevValues: { id: number, like: boolean, dislike: boolean }[] = []

  //#endregion

  //#region Constructor

  ngOnInit() {

    this.#productService
      .getFavoritesDislikes()
      .subscribe(([favorites, dislikes]) => {
        this.likes.set(favorites.data ?? []);
        this.dislikes.set(dislikes.data ?? []);
      });
  }

  //#endregion

  //#region Methods

  changeFavoriteState(event: FavOrDislike) {

    if (!event.isDisliked === event.value)
      return;

    this.likes.update(value => {

      const index = value.findIndex(x => x.id === event.id);

      if (index !== -1) {

        const product = value.splice(index, 1);

        if (event.isDisliked && event.value)
          this.dislikes.update(dislikes => {
            product[0].fav = false;
            product[0].disliked = true;
            dislikes.push(product[0]);
            return [...dislikes];
          });
      }

      return [...value];
    });
  }

  changeDislikeState(event: FavOrDislike) {

    if (event.isDisliked === event.value)
      return;

    this.dislikes.update(value => {

      const index = value.findIndex(x => x.id === event.id);

      if (index !== -1) {

        const product = value.splice(index, 1);

        if (!event.isDisliked && event.value)
          this.likes.update(dislikes => {
            product[0].fav = true;
            product[0].disliked = false;
            dislikes.push(product[0]);
            return [...dislikes];
          });
      }

      return [...value];
    });
  }

  //#endregion
}
