<div class="container-fluid animated fadeIn">
  <div class="row">
    <aside class="col-sm-3 col-md-2 d-md-block bg-opacity-10 sidebar"
      [class.sidebar-height-global-message]="hasGlobalMessages()" [class.sidebar-height]="!hasGlobalMessages()"
      [class.showing-mobile-footer]="isShowingMobileFooter()">

      <div class="container">
        <h3 class="mt-4">Settings</h3>

        @for (item of itemsList(); track $index) {
        @if (item.display) {
        <h5 class="mt-4">{{ item.title }}</h5>
        <ul class="nav flex-column">
          @for (subItem of item.items; track $index) {
          @if(subItem.display) {
          <li class="nav-item px-2">
            <a class="nav-link link-dark position-relative link-underline-dark" [routerLink]="subItem.link"
              routerLinkActive="active-item fw-bold">
              <i
                class="fa-solid fa-chevron-right position-absolute top-50 start-0 translate-middle selected-item-icon"></i>
              {{subItem.title2 && !(session()?.settings?.requireAddress) ? subItem.title2 : subItem.title}}
            </a>
          </li>
          }
          }
        </ul>
        }
        }
      </div>

    </aside>
    <main class="col-sm-9 col-md-10 ms-sm-auto px-md-4 main" [class.sidebar-height-global-message]="hasGlobalMessages()"
      [class.sidebar-height]="!hasGlobalMessages()" [class.showing-mobile-footer]="isShowingMobileFooter()">
      <div class="settings__breadcrumb">
        <span role="button" class="btn-action" (click)="goBack()"><i class="fa-solid fa-arrow-left"></i>Back</span>
      </div>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>