import { PermissionNames } from "../types/account.types"
import { RightElement, SettingsParentPage } from "./header.types"

type NavigationItems = {
  route: string;
  name: string;
  isExternal?: boolean;
  mobile?: boolean;
  submenu?: {
    route: string;
    name: string;
    isExternal?: boolean;
  }[];
}

export const NAVIGATION_ITEMS: NavigationItems[] = [
  {
    route: '/shop',
    name: 'Market'
  },
  {
    route: '',
    name: 'About us',
    submenu: [
      {
        route: 'pages/why-farmbox',
        name: 'Why FarmBox?'
      },
      {
        route: '/pages/faq',
        name: 'FAQ'
      },
      {
        route: '/pages/sustainable',
        name: 'Sustainability '
      },
      {
        route: '/pages/how-it-works',
        name: 'How it Works'
      },
      {
        route: '/pages/producers',
        name: 'Farmers & Producers'
      },
    ]
  },
  {
    route: 'https://blog.farmboxdelivery.com/category/recipes/',
    name: 'Recipes',
    isExternal: true
  },
  {
    route: '/pages/blog',
    name: 'Blog'
  }
]

export const SETTINGS_PAGES: SettingsParentPage[] = [
  {
    icon: 'account_circle',
    name: 'Account settings',
    children: [
      {
        route: '/settings/account/profile',
        name: 'Profile',
        permissionName: PermissionNames.settingsProfile
      },
      {
        route: '/settings/account/subscriptions',
        name: 'Subscriptions',
        permissionName: PermissionNames.settingsSubscription
      },
      {
        route: '/settings/account/deliveries',
        name: 'Deliveries',
        name2: 'Pick-Ups',
        permissionName: PermissionNames.settingsDeliveries
      },
      {
        route: '/settings/account/friend-referrals',
        name: 'Friend Referrals',
        permissionName: PermissionNames.settingsFriednReferrals
      },
    ],
    permissionName: PermissionNames.settings
  },
  {
    icon: 'credit_card',
    name: 'Billing settings',
    children: [
      {
        route: '/settings/billing/payment-method',
        name: 'Payment Method',
        permissionName: PermissionNames.settingsPaymentMethod
      },
      {
        route: '/settings/billing/membership',
        name: 'Membership',
        permissionName: PermissionNames.settingsMemebership
      },
      {
        route: '/settings/billing/order-and-billing',
        name: 'Order & Billing',
        permissionName: PermissionNames.settingsOrderHistory
      },
      {
        route: '/settings/billing/invoice-history',
        name: 'Invoice History',
        permissionName: PermissionNames.settingsInvoiceHistory
      },
    ],
    permissionName: PermissionNames.settings
  },
]

export const RIGHT_ELEMENTS: RightElement[] = [
  {
    tooltip: 'Favorites',
    route: '/favorites',
    icon: 'favorite',
    permissionName: PermissionNames.shop
  },
  {
    tooltip: 'Buy Again',
    route: '/buy-again',
    icon: 'verified',
    permissionName: PermissionNames.shop
  },
  {
    tooltip: 'Cart',
    route: '/order',
    icon: 'shopping_cart',
    showBadge: true,
    permissionName: PermissionNames.order,
    showWithoutPermission: true
  },
]
