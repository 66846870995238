import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, QueryList, ViewChildren, afterNextRender, computed, inject } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SignalsStoreService } from '../shared/signals-store.service';
import { ProductCardComponent } from '../shared/product-card/product-card.component';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { ProductsService } from '../product/products.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { toObservable } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { StockService } from '../stock/stock.service';
import { LARGE } from "../../scss/responsive/responsive";
import { HeaderService } from '../shared/header/header.service';
import { ResolutionService } from '../shared/resolution.service';
import { EmptyMessageComponent } from '../shared/empty-message/empty-message.component';
import { MobileProductCardComponent } from '../shared/mobile-product-card/mobile-product-card.component';
import { BasicBundleInfo } from '../shared/types/order.type';
import { OrderService } from '../shared/order.service';
import { MenuService } from '../shared/menu.service';
import { ShopService } from './shop.service';
import { Router } from '@angular/router';
import { FooterComponent } from '../shared/footer/footer.component';

@Component({
  selector: 'app-shop',
  standalone: true,
  templateUrl: './shop.component.html',
  styleUrl: './shop.component.scss',
  imports: [
    CommonModule,
    SidebarComponent,
    ProductCardComponent,
    MobileProductCardComponent,
    NgbModule,
    MatProgressBarModule,
    EmptyMessageComponent,
    FooterComponent
  ]
})
export class ShopComponent implements OnInit {

  @ViewChildren('subcategory')
  subcategories!: QueryList<ElementRef>;

  private productsService = inject(ProductsService);
  private headerService = inject(HeaderService);
  private resolutionService = inject(ResolutionService);
  #orderService = inject(OrderService);
  #router = inject(Router);
  #menuService = inject(MenuService);
  #shopService = inject(ShopService);

  categoryBanner = computed(() => this.#shopService.categoryBanner());

  signalsStore = inject(SignalsStoreService);
  stockService = inject(StockService);

  isSidebarOpen = this.signalsStore.isSidebarOpen;
  hasSession = this.signalsStore.hasSession;
  selectedCategory = this.signalsStore.selectedCategory;

  lastSubcategory: string | null = null;
  productsSignal = computed(() => {
    const sortedProducts = this.productsService.productsSignal();
    this.lastSubcategory = null;
    return sortedProducts.map((product: any) => {
      if (product.subcategory.name !== this.lastSubcategory) {
        product.showSubcategory = true;
        this.lastSubcategory = product.subcategory.name;
      } else {
        product.showSubcategory = false;
      }
      return product;
    });
  });
  categoriesSignal: any = computed(() => this.productsService.productsSignal());
  marketStatus = computed(() => this.signalsStore.marketStatus());
  closedMarket = computed(() => {
    const im = this.isMobile();
    const ms = this.marketStatus();
    return {
      title: !ms.isOpen && im ?
        `Our Online Farmer's Market Open ${ms.openingDay.toUpperCase()} Morning!` :
        `Our Online Farmer's Market Open ${ms.openingDay.toUpperCase()} Morning!`,
      legend: `We're busy sourcing next week's availability! Check back Friday to begin shopping.`
    };
  })
  sidebarChanged = toObservable(this.signalsStore.sidebarFilterChanged);

  isContentLoading = computed(() => this.stockService.isLoading() || this.productsService.isLoading());
  isContentLoaded = computed(() => this.stockService.isLoaded() || this.productsService.isLoaded());

  isMobile = computed(() => this.resolutionService.isMobile());

  bundles = computed(() => this.#orderService.getBundlesLikeSubscriptions());

  emptyMessage = 'No products were found.';
  hasGlobalMessages = computed(() => !!this.signalsStore.globalMessages().length);

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    afterNextRender(() => {
      const isMobile = window.innerWidth <= LARGE;
      if (isMobile) this.signalsStore.isSidebarOpen.set(false);
    });
  }

  ngOnInit(): void {
    this.sidebarChanged
      .pipe(tap(() => this.productsService.filterBySidebarChange()))
      .subscribe();
    this.headerService.producerRedirection();

    if (!isPlatformBrowser(this.platformId)) return;
    const selectedProductId = localStorage.getItem('selectedProductId');
    if (selectedProductId != null) {
      const intervalId = setInterval(() => {
        const scrollToTop = document.getElementById(selectedProductId!.toString());
        if (scrollToTop) {
          scrollToTop.scrollIntoView({ block: 'start' });
          localStorage.removeItem('selectedProductId');
          clearInterval(intervalId);
        }
      }, 1000);
    }
  }

  scrollToTop(): void {
    const scrollToTop = document.getElementById('scroll-to-top-ref');
    if (scrollToTop) {
      scrollToTop.scrollIntoView({ block: 'start' });
    }
  }

  onScroll(event: any) {

    // Nothing products in shop

    if (!this.subcategories.length)
      return;

    const contentY = document
      .getElementsByClassName('layout-shop__main')[0]
      ?.getBoundingClientRect().y ?? 0;

    if (!contentY)
      return;

    // Search the element most near when scroll is down.

    let categoryId = null;
    let subCategoryId = null;

    // Validate if the scroll is bottom

    let element: ElementRef<any> | undefined;
    const isBottom = event.srcElement.scrollHeight - event.srcElement.scrollTop <= event.srcElement.clientHeight + 5;

    if (isBottom)
      element = this.subcategories.toArray().reverse()[0];
    else {

      element = this.subcategories
        .find(x => {
          const diffY = x.nativeElement.getBoundingClientRect().y - contentY;

          if (diffY < 0) return false;
          return diffY >= -300 && diffY <= 100;
        });

      if (!element) {

        // Search the last element most near when scroll is up.

        element = this.subcategories
          .toArray()
          .reverse()
          .find(x => {
            const diffY = x.nativeElement.getBoundingClientRect().y - contentY;
            return diffY < 0;
          });
      }
    }

    if (!element)
      return;

    // Get the category and subcategory attibutes

    categoryId = +element.nativeElement.getAttribute('category-id');
    subCategoryId = +element.nativeElement.getAttribute('sub-category-id');

    // If yet not change of category, don't do nothing.

    if (this.signalsStore.categoryInViewPort()?.categoryId === categoryId &&
      this.signalsStore.categoryInViewPort()?.subCategoryId === subCategoryId)
      return;

    // Emit the new visible category.

    this.signalsStore
      .categoryInViewPort
      .set({
        categoryId,
        subCategoryId
      });
  }

  goToCustomBox(bundle: BasicBundleInfo) {
    this.#router
      .navigate([`/shop/custom-box/subscription/${bundle.id}`]);
  }

  getSubCategoryBanner(categoryId: number, subCategoryId: number): string {

    const category = this.#menuService
      .menu()
      .find(x => x.id === categoryId);

    if (!category)
      return '';

    const subcategory = category.subCategories
      .find((x: any) => x.id === subCategoryId);

    return !subcategory
      ? ''
      : !subcategory.banners
        ? ''
        : subcategory.banners[this.isMobile() ? 'mobile' : 'desktop'];
  }
}
