import { Component, computed, inject, signal } from '@angular/core';
import { BlogService } from './blog.service';
import { finalize, tap } from 'rxjs';
import { BlogCategory, BlogPost } from './blog.types';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { PostComponent } from './post/post.component';
import { Router } from '@angular/router';
import { ResolutionService } from '../../shared/resolution.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    CommonModule,
    PostComponent
  ],
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.scss'
})
export class BlogComponent {

  //#region Services

  #blogService = inject(BlogService);

  #router = inject(Router);

  #resolutionService = inject(ResolutionService);

  //#endregion

  //#region Properties

  isLoading = signal(true);

  blogs = signal<BlogPost[]>([]);

  blog = signal<BlogPost | null>(null);

  categories = signal<BlogCategory[]>([{
    id: 0,
    name: 'All Post',
    isSelected: false,
    color: ''
  }]);

  total = signal(0);

  size = computed(() => this.#blogService.size());

  page = computed(() => this.#blogService.page());

  categoryId = computed(() => this.#blogService.categoryId());

  backgroundImage = signal('');

  isMobile = computed(() => this.#resolutionService.isMobile());

  //#endregion

  //#region Constructors

  constructor() {
    this.#getCategories();
    this.#getAllPosts();
  }

  //#endregion

  //#region Methods

  #getAllPosts() {

    this.#blogService
      .getAll()
      .pipe(
        tap(response => {
          this.blogs.set(response.data?.blogs ?? []);
          this.total.set(response.data.length);
          this.blog.set(this.blogs().find(x => x.featured) ?? this.blogs()[0]);
          this.backgroundImage.set('url(' + (this.blog()?.image || 'assets/images/product-placeholder.webp') + ')');
        })
      )
      .subscribe();
  }

  #getCategories() {

    this.isLoading.set(true);

    this.#blogService
      .getCategories()
      .pipe(
        tap(response => {

          this.categories.update(value => {
            value.push(...response.data);
            return value;
          });

          const index = this.categories().findIndex(x => x.id === this.#blogService.categoryId());
          this.categories()[index].isSelected = true;
        }),
        finalize(() => this.isLoading.set(false))
      )
      .subscribe();
  }

  #resetFilters(categoryId = 0) {
    this.#blogService.page.set(0);
    this.#blogService.categoryId.set(categoryId);
  }

  changeCategory(category: BlogCategory) {
    this.categories().forEach(x => x.isSelected = false);
    category.isSelected = true;
    this.#resetFilters(category.id);
    this.#getAllPosts();
  }

  handlePageEvent(event: any) {

    if (this.#blogService.size() !== event.pageSize) {
      this.#blogService.size.set(event.pageSize);
      this.#blogService.page.set(0);
    } else {
      this.#blogService.page.set(event.pageIndex);
    }

    this.#getAllPosts();
  }

  goToDetail(blog: BlogPost | null) {
    this.#router.navigate([`/pages/blog-post/${blog?.url}`]);
  }

  //#endregion
}
