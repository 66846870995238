<div class="page-title background-banner">
  <h1 class="h1-semibold head-font">Farmers &amp; Producers</h1>
</div>

<div class="about-banner">
  <div class="container">
    <div class="leaf-left">
      <img src="assets/images/landing/leaf-1.svg" alt="Leaf 1" />
    </div>

    <div class="leaf-center">
      <img src="assets/images/landing/leaf-2.svg" alt="Leaf 2" />
    </div>

    <div class="leaf-right">
      <img src="assets/images/landing/leaf-3.svg" alt="Leaf 3" />
    </div>

    We visit farms and producers and carefully select only the best local and small batch products to feature in our
    farmbox. You can shop confidently knowing that all the products you see on our site are grown, raised or produced
    using the highest standards and are therefore naturally nutrient-rich and delicious. We believe it’s our
    responsibility to not only help food waste but support our local Texas farmers while doing so.
  </div>
</div>

<section class="page-wrap animated fadeIn">
  <div class="container">

    <div class="row">
      @defer (on immediate; when producersInView().length) {
      @if (producersInView()) {
      @for (producer of producersInView(); track producer.id) {
      @defer (on viewport; prefetch on immediate) {
      <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
        <div class="producer">
          <img class="producer_img" [src]="producer.img" alt="{{producer.name}}" (error)="handleImageError($event)" />
          <div class="producer_detail">
            <div class="producer_title">
              {{producer.name}}
            </div>
            <div class="producer_desc" [innerHTML]="producer.description"></div>
          </div>
          <button type="button" class="btn btn-secondary" [routerLink]="['/pages/producers/' + producer.detailUrl]">
            Read more
          </button>
        </div>
      </div>
      } @placeholder {
      <div class="placeholder-glow animated fadeIn">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 animated fadeIn bg-secondary"></div>
      </div>
      }
      }
      }
      }
    </div>

    @if (showLoadMore()) {
    <div class="d-flex align-items-center justify-content-center mt-5">
      <button type="button" class="btn btn-link" (click)="loadMore()">
        Load more
      </button>
    </div>
    }

  </div>
</section>
