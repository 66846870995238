import { Component, inject } from '@angular/core';
import { SignalsStoreService } from '../signals-store.service';
import { ProductCartPreview } from '../product-card/product-cart-preview/product-cart-preview.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-added-notification',
  standalone: true,
  imports: [
    ProductCartPreview
  ],
  templateUrl: './product-added-notification.component.html',
  styleUrl: './product-added-notification.component.scss'
})
export class ProductAddedNotificationComponent {
  private signalsStoreService = inject(SignalsStoreService);
  private router = inject(Router);

  justAddedProduct = this.signalsStoreService.justAddedProduct;

  clearJustAddedProduct() {
    this.justAddedProduct.set(null);
  }

  goToCart() {
    this.clearJustAddedProduct();
    this.router.navigate(['/cart'])
  }
}
