<div class="d-flex gap-4 justify-content-center mx-auto mb-4" [(ngModel)]="suggestedProductsSelected">
  @for (products of suggestedProducts; track $index) {
  <div class="px-3">
    <button class="btn-products" (click)="updateProductsSelection(products)"
      [class.selected]="products.id === suggestedProductsSelected()?.id">
      @if (products.id === suggestedProductsSelected()?.id) {
      <strong>{{ products.name }}</strong>
      } @else {
      {{ products.name }}
      }
    </button>
  </div>
  }
</div>
<div class="container-fluid">
  @switch(suggestedProductsSelected()){
  @case(suggestedProducts[1]) {
  <div class="row">
    @defer (on immediate; when subscriptionCard().length) { @if (subscriptionCard())
    { @for (product of subscriptionCard(); track product.id) { @defer (on
    viewport; prefetch on immediate) {
    @if (isMobile()) {
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 animated fadeIn">
      <div class="product-card-grid__item">
        <app-mobile-product-card [card]="{product}" [isSignupFlow]="true" (click)="selectCard(product)"
          [class.selected]="selectedCard === product"></app-mobile-product-card>
      </div>
    </div>
    } @else {
    <div class="col-xl-4 col-lg-4 col-12 animated fadeIn mb-3">
      <div class="product-card-grid__item">
        <app-product-card class="product-card__box" [card]="{ product }" [isSignupFlow]="true"
          [selectedCard]="selectedCard" (click)="selectCard(product)"
          [class.selected]="selectedCard === product"></app-product-card>
      </div>
    </div>
    }
    } @placeholder {
    <div class="col-xl-4 col-lg-4 col animated fadeInUp fadeIn mb-3">
      <div class="card position-relative border p-3 row-gap-3" aria-hidden="true">
        <span class="placeholder bg-secondary col-12"></span>
        <span class="placeholder bg-secondary col-6"></span>
        <div class="placeholder bg-secondary col-12" style="height: 100px"></div>
        <div class="card-body">
          <h5 class="card-title placeholder-glow">
            <span class="placeholder bg-secondary col-6"></span>
          </h5>
          <p class="card-text placeholder-glow">
            <span class="placeholder bg-secondary col-7"></span>
            <span class="placeholder bg-secondary col-4"></span>
            <span class="placeholder bg-secondary col-4"></span>
            <span class="placeholder bg-secondary col-6"></span>
            <span class="placeholder bg-secondary col-8"></span>
          </p>
        </div>
      </div>
    </div>
    } }
    <div class="form-row-action">
      <div class="d-grid gap-2 mt-4 col-md-4 col-12 mx-auto">
        <button class="btn btn-secondary" (click)="continue()">
          Continue
        </button>
      </div>
    </div>
    } }
  </div>
  }
  @case (suggestedProducts[2]) {
  <div class="row">
    @defer (on immediate; when subscriptionCard().length) { @if (subscriptionCard())
    { @for (product of subscriptionCard(); track product.id) { @defer (on
    viewport; prefetch on immediate) {
    <div class="col-xl-4 col-lg-4 col-12 animated fadeIn mb-3">
      <div class="product-card-grid__item">
        <app-product-card class="product-card__box" [card]="{ product }" [isSignupFlow]="true"
          [selectedCard]="selectedCard" (click)="selectCard(product)"
          [class.selected]="selectedCard === product"></app-product-card>
      </div>
    </div>
    } @placeholder {
    <div class="col-xl-4 col-lg-4 col animated fadeInUp fadeIn mb-3">
      <div class="card position-relative border p-3 row-gap-3" aria-hidden="true">
        <span class="placeholder bg-secondary col-12"></span>
        <span class="placeholder bg-secondary col-6"></span>
        <div class="placeholder bg-secondary col-12" style="height: 100px"></div>
        <div class="card-body">
          <h5 class="card-title placeholder-glow">
            <span class="placeholder bg-secondary col-6"></span>
          </h5>
          <p class="card-text placeholder-glow">
            <span class="placeholder bg-secondary col-7"></span>
            <span class="placeholder bg-secondary col-4"></span>
            <span class="placeholder bg-secondary col-4"></span>
            <span class="placeholder bg-secondary col-6"></span>
            <span class="placeholder bg-secondary col-8"></span>
          </p>
        </div>
      </div>
    </div>
    } }
    <div class="form-row-action">
      <div class="d-grid gap-2 mt-4 col-md-4 col-12 mx-auto">
        <button class="btn btn-secondary" (click)="continue()">
          Continue
        </button>
      </div>
    </div>
    } }
  </div>
  }
  @default {
  <div class="row">
    @defer (on immediate; when subscriptionCard().length) { @if (subscriptionCard())
    { @for (product of subscriptionCard(); track product.id) { @defer (on
    viewport; prefetch on immediate) {
    <div class="col-xl-4 col-lg-4 col-12 animated fadeIn mb-3">
      <div class="product-card-grid__item">
        <app-product-card class="product-card__box" [card]="{ product }" [isSignupFlow]="true"
          [selectedCard]="selectedCard" (click)="selectCard(product)"
          [class.selected]="selectedCard === product"></app-product-card>
      </div>
    </div>
    } @placeholder {
    <div class="col-xl-4 col-lg-4 col animated fadeInUp fadeIn mb-3">
      <div class="card position-relative border p-3 row-gap-3" aria-hidden="true">
        <span class="placeholder bg-secondary col-12"></span>
        <span class="placeholder bg-secondary col-6"></span>
        <div class="placeholder bg-secondary col-12" style="height: 100px"></div>
        <div class="card-body">
          <h5 class="card-title placeholder-glow">
            <span class="placeholder bg-secondary col-6"></span>
          </h5>
          <p class="card-text placeholder-glow">
            <span class="placeholder bg-secondary col-7"></span>
            <span class="placeholder bg-secondary col-4"></span>
            <span class="placeholder bg-secondary col-4"></span>
            <span class="placeholder bg-secondary col-6"></span>
            <span class="placeholder bg-secondary col-8"></span>
          </p>
        </div>
      </div>
    </div>
    } }
    <div class="form-row-action">
      <div class="d-grid gap-2 mt-4 col-md-4 col-12 mx-auto">
        <button class="btn btn-secondary" (click)="continue()">
          Continue
        </button>
      </div>
    </div>
    } }
  </div>
  }
  }
</div>
