<app-notification></app-notification>
@if (product()) {
<div class="container-fluid main-container__full">
  <!-- Breadcrumbs -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a (click)="gotoCategory()"><span class="material-symbols-outlined">home</span></a>
      </li>
      <li class="breadcrumb-item active" aria-current="page"><span class="fw-bold">
          {{ product()?.name }}
        </span></li>
    </ol>
  </nav>

  <h1>{{selectedAttribute()}}</h1>

  <!-- Product details -->
  <div class="row">
    <!-- Product Images -->
    <div class="col-lg-4" [class.mb-4]="!showMultipleImages()">
      <div class="product__img">
        @if (isContentLoaded()) {

        <!-- Toolbar (favorite and more options) -->
        @if (signalsStoreService.hasSession()) {
        <div class="border-0 p-0 toolbar">

          <!-- Like -->
          @if (isFavorite()) {
          <span (click)="handleFavoriteChange()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#38424b">
              <path
                d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" />
            </svg>
          </span>
          } @else {
          <span (click)="handleFavoriteChange()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#38424b">
              <path
                d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
            </svg>
          </span>
          }

          <!-- Dislike -->
          @if (isDislike()) {
          <span (click)="prevDislikeProduct()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#38424b">
              <path
                d="M240-840h400v520L360-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 1.5-15t4.5-15l120-282q9-20 30-34t44-14Zm480 520v-520h160v520H720Z" />
            </svg>
          </span>
          } @else {
          <span (click)="prevDislikeProduct()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#38424b">
              <path
                d="M240-840h440v520L400-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 2-15t4-15l120-282q9-20 30-34t44-14Zm360 80H240L120-480v80h360l-54 220 174-174v-406Zm0 406v-406 406Zm80 34v-80h120v-360H680v-80h200v520H680Z" />
            </svg>
          </span>
          }

        </div>
        }

        <app-carousel [items]="productImages()" [type]="'withThumbnail'" [showMultipleImages]="showMultipleImages()"
          [showWhatsInsideButton]="product()?.bundle?.items?.length > 0" [showSaleTag]="showDiscountTag()"
          (onWhatsInsideClick)="whatsInside($event)"></app-carousel>
        }
      </div>
    </div>


    <div class="col-md-6 col-lg-4 pe-5">
      <!-- Product Info -->
      <div class="product__info">
        <!-- Title -->
        <h2 class="product__name">{{ product()?.name }}</h2>
        <!-- Subtitle -->
        <h6 class="product__producer">{{ product()?.producer?.name }}</h6>
        <!-- Favorites count -->
        <p class="product__fav-count"><i class="fa-solid fa-heart"></i> {{ likeTotals() }} {{likeTotals() > 1 ?
          'Favorites': 'Favorite'}}</p>
        <!-- Price -->
        <h2 class="product__price">{{ (variant()?.price || product()?.price) | currency }}</h2>

        <!-- Description -->
        <div class="product-desc-text" [innerHTML]="product()?.description.short"></div>

        <!-- Tags Icons -->
        <ul class="tags">
          @for (tag of product()?.tags || []; track $index) {
          <li>
            {{tag.name}}
          </li>
          }
        </ul>
      </div>
    </div>
    <!-- Product Summary -->
    <div class="col-md-6 col-lg-4">
      <div class="product__summary">
        @if (isContentLoaded()) {
        <app-product-card [card]="productCardSummary()" #productCard (selectedVariant)="onSelectedVariant($event)"
          (outShowDiscountTag)="onChangeShowDisccountTag($event)"></app-product-card>
        }
      </div>
    </div>
  </div>

  <div class="row mt-5 mb-5">
    <div class="col">
      <div class="accordion" id="accordionPanelsStayOpenExample">
        <!-- Product description -->
        @if(product().description.long) {
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed title-section" type="button" data-bs-toggle="collapse"
              data-bs-target="#productDescription" aria-expanded="false" aria-controls="productDescription">
              Product Description
            </button>
          </h2>
          <div id="productDescription" class="accordion-collapse collapse">
            <div class="accordion-body" [innerHTML]="product()?.description.long"></div>
          </div>
        </div>
        }
        <!-- About the producer -->
        @if(product().producer?.description) {
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed title-section" type="button" data-bs-toggle="collapse"
              data-bs-target="#aboutTheProducer" aria-expanded="false" aria-controls="aboutTheProducer">
              About the Producer
            </button>
          </h2>
          <div id="aboutTheProducer" class="accordion-collapse collapse">
            <div class="accordion-body">
              <div class="d-flex gap-3 align-items-center">
                <img src="{{product().producer?.img || 'assets/images/product-placeholder.webp'}}"
                  class="about-producer-img d-none d-sm-block" alt="Producer description">
                <div [innerHTML]="product()?.producer?.description"></div>
              </div>
            </div>
          </div>
        </div>
        }
        <!-- Ingredientes -->
        @if (product().description.ingredients) {
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed title-section" type="button" data-bs-toggle="collapse"
              data-bs-target="#productIngredients" aria-expanded="false" aria-controls="productIngredients">
              Ingredients
            </button>
          </h2>
          <div id="productIngredients" class="accordion-collapse collapse">
            <div class="accordion-body" [innerHTML]="product()?.description.ingredients"></div>
          </div>
        </div>
        }
        <!-- Recipes -->
        @if(product().description.recipes) {
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed title-section" type="button" data-bs-toggle="collapse"
              data-bs-target="#productRecipes" aria-expanded="false" aria-controls="productRecipes">
              Recipes
            </button>
          </h2>
          <div id="productRecipes" class="accordion-collapse collapse">
            <div class="accordion-body" [innerHTML]="product()?.description.recipes"></div>
          </div>
        </div>
        }
        <!-- Storage -->
        @if (product().description.storage) {
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed title-section" type="button" data-bs-toggle="collapse"
              data-bs-target="#productStorage" aria-expanded="false" aria-controls="productStorage">
              Storage
            </button>
          </h2>
          <div id="productStorage" class="accordion-collapse collapse">
            <div class="accordion-body" [innerHTML]="product()?.description.storage"></div>
          </div>
        </div>
        }
        <!-- You might also like -->
        @if(carouselItems()?.length) {
        <section class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed title-section" type="button" data-bs-toggle="collapse"
              data-bs-target="#youMightAlsoLike" aria-expanded="true" aria-controls="youMightAlsoLike">
              You might also like
            </button>
          </h2>
          <div id="youMightAlsoLike" class="accordion-collapse collapse show">
            <div class="row">
              @if (isContentLoaded()) {
              <div class="product-highlight">
                <div class="product-highlight__body">
                  <app-carousel [items]="carouselItems()"></app-carousel>
                </div>
              </div>
              }
            </div>
          </div>
        </section>
        }
      </div>
    </div>
  </div>
</div>
} @else {
<mat-progress-bar class="position-absolute rounded" mode="indeterminate" value="accent"></mat-progress-bar>
<!-- <div class="container-fluid p-5">
    <div class="row">
      <div class="col m-4 mt-0 ms-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#"><i class="fas fa-home"></i></a></li>
            <li class="breadcrumb-item"><span class="placeholder"></span></li>
            <li class="breadcrumb-item active" aria-current="page"><span class="fw-bold placeholder"></span></li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">

      <div class="col-4">
        <div class="col">
          <div class="placeholder w-100 vh-100"></div>
        </div>
      </div>

      <div class="col-4 pe-5">

        <h6><span class="placeholder">Producer Name</span></h6>

        <h2><span class="placeholder">Product Name</span></h2>

        <p><i class="fas fa-heart"></i> <span class="placeholder">Favorites Count</span></p>

        <h2 class="product__price mb-2"><span class="placeholder">Price</span></h2>

        <h3>About the product</h3>

        <div><span class="placeholder w-100"></span></div>

        <ul class="list-group list-group-horizontal">
          <li class="list-group-item pt-0 ps-0 tag-container">
            <img src="#">
            <span class="placeholder">Tag Name</span>
          </li>
          </ul>
      </div>

      <div class="col-4 d-flex justify-content-end">
        <div class="placeholder w-100 vh-100"></div>
      </div>
    </div>

    <div class="row mt-5 mb-5">
      <div class="col">

        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                Product description
              </button>
            </h2>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
              <div class="accordion-body">
                <div class="row">
                  <div class="col-2 d-flex align-items-center">
                    <img src="#" class="img-thumbnail rounded" alt="">
                  </div>
                  <div class="col-10">
                    <span class="placeholder w-100"></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    @if(carouselItems()?.length) {
      <div class="row mb-4">
        <div class="col-12">
          <h3>You might also like</h3>
        </div>
      </div>
      <div class="row">
        @if (isContentLoaded()) {
          <app-carousel [items]="carouselItems()"></app-carousel>
        }
      </div>
    }
  </div> -->

}
