import { CanActivateFn } from '@angular/router';
import { inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { SignalsStoreService } from '../signals-store.service';
import { LocalStorageService } from '../local-storage.service';
import { Session } from '../types/session.type';
import { LOCALSTORAGE_KEYS } from '../constants/databases';
import { environment } from '../../../environments/environment';
import { isANoPaymentMethodUser, isNoAddressUser, specialNavigatrionParamAndValue, isAddressUpdateRequired } from '../common/utils';
import { ModalContentService } from '../modal-content/modal-content.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { eCommercePermissions, permission, PermissionNames } from '../types/account.types';
import { isPlatformServer } from '@angular/common';

export const maintenanceGuard: CanActivateFn = (route, state) => {
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
  const maintenance = localStorageService.get(LOCALSTORAGE_KEYS.MAINTENANCE_MODE) || false;
  if (maintenance) {
    router.navigate(['/maintenance'])
    return false;
  }
  return true;
}

export const maintenanceOffGuard: CanActivateFn = (route, state) => {
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
  const maintenance = localStorageService.get(LOCALSTORAGE_KEYS.MAINTENANCE_MODE) || false;
  if (!maintenance) {
    router.navigate(['/'])
    return false;
  }
  return true;
}

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const signalsStoreService = inject(SignalsStoreService);

  if (signalsStoreService.hasSession()) {
    router.navigate(['/']);
    return false;
  }
  return true;
};

export const checkMembershipPermissions: CanActivateFn = (route, state) => {
  const isServer = isPlatformServer(inject(PLATFORM_ID));
  if (isServer) return false;
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
  const fullRoute = `/${route.url.map(e => e.path).join('/')}`;

  const sessionStored: Session | null = localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
  if (!sessionStored) {
    // If it is an special route, should save it and navigate to login for reditorec to it after login or
    // if is trying to navigate to a view available without session (/shop)::
    if (route.queryParams?.[specialNavigatrionParamAndValue.param]?.toLowerCase() === specialNavigatrionParamAndValue.value ||
      !fullRoute.includes('/shop')) {
      localStorageService.set(LOCALSTORAGE_KEYS.ROUTE_TO_NAVIGATE_AFTER_LOGIN, state.url);
      router.navigate(['/login']);
      return false;
    }
  }

  // Get allowed routes based on the user membership:
  const permissions: eCommercePermissions | null | any = localStorageService.get(LOCALSTORAGE_KEYS.PERMISSIONS);
  let allowedRoutes: { route: string, default?: boolean }[] = [];

  // Validate if the proyect has special membership default route configured and the user has an special membership (isLimited == true):
  const configuredDefaultRotue = environment.config.flows.specialMembership.defaultRoute || null;
  const defaultRoute = sessionStored?.settings.isLimited ? configuredDefaultRotue : '/shop'
  if (defaultRoute) {
    allowedRoutes.push({ route: defaultRoute, default: true });
  } else {
    allowedRoutes.push({ route: '/', default: true });
  }

  if (permissions) {
    for (const key in permissions) {
      if (Object.prototype.hasOwnProperty.call(permissions, key)) {

        const element: permission | any = permissions[key];

        if (key === PermissionNames.settings) {
          for (const subkey in element) {
            if (Object.prototype.hasOwnProperty.call(element, subkey)) {
              const subElement: permission = element[subkey];
              if (subElement.allowed && subElement.eCommRoute !== defaultRoute)
                allowedRoutes.push({ route: subElement.eCommRoute })
            }
          }

          if (allowedRoutes.some(ar => ar.route.includes(PermissionNames.settings))) allowedRoutes.push({ route: `/${PermissionNames.settings}` })
        } else if (element.allowed && element.eCommRoute !== defaultRoute)
          allowedRoutes.push({ route: element.eCommRoute })
      }
    }
  }

  if (!allowedRoutes.some(e => fullRoute.includes(e.route) && e.route !== '/')) {
    const defaultView = allowedRoutes.find(route => route.default) || { route: '/login' };
    router.navigate([defaultView.route]);
    return false;
  }

  return true;
};

export const preventForAbandoneOrNoAddressdUser: CanActivateFn = (route, state) => {
  return validateAbandonedOrNoAddressUser(true);
};

export const checkAbandoneOrNoAddressdUser: CanActivateFn = (route, state) => {
  return validateAbandonedOrNoAddressUser(false);
};

const validateAbandonedOrNoAddressUser = (prevent: boolean) => {
  const localStorageService = inject(LocalStorageService);
  const modalService = inject(ModalContentService);
  const activeModal = inject(NgbModal);
  const router = inject(Router)

  const sessionStored: Session | null = localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
  if (!sessionStored) {
    return !prevent;
  }

  if (isANoPaymentMethodUser(localStorageService, modalService, activeModal)) {
    return !prevent;
  }

  if (isAddressUpdateRequired(localStorageService, modalService, activeModal, router)) {
    return !prevent;
  }

  if (isNoAddressUser(localStorageService, modalService, activeModal, router)) {
    return !prevent;
  }

  return true;
};

export const checkNoPaymentMethodUser: CanActivateFn = (route, state) => {
  const localStorageService = inject(LocalStorageService);
  const modalService = inject(ModalContentService);
  const activeModal = inject(NgbModal);

  const sessionStored: Session | null = localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
  if (!sessionStored) {
    return false;
  }

  isANoPaymentMethodUser(localStorageService, modalService, activeModal)
  return true;
};
