<div class="container-fluid">
  <p class="signup__sub-head-text">Pick one of our farm boxes</p>
  <div class="row mt-5">
    @defer (on immediate; when subscriptionCard().length) {
    @if (subscriptionCard())
    {
    @for (product of subscriptionCard(); track product.id) {
    @defer (on viewport; prefetch on immediate) {
    @if (isMobile()) {
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 animated fadeIn">
      <div class="product-card-grid__item">
        <app-mobile-product-card [card]="{product}" [subscribeAndcustomizeBox]="true" [isSignupFlow]="true"
          [class.selected]="selectedCard === product"
          (farmBoxCard)="selectedFarmBox($event, product)"></app-mobile-product-card>
      </div>
    </div>
    } @else {
    <div class="col-xl-4 col-lg-4 col-12 animated fadeIn mb-3">
      <div class="product-card-grid__item">
        <app-product-card class="product-card__box" [card]="{ product }" [subscribeAndcustomizeBox]="true"
          [isSignupFlow]="true" [selectedCard]="selectedCard" [class.selected]="selectedCard === product"
          (farmBoxCard)="selectedFarmBox($event, product)"></app-product-card>
      </div>
    </div>
    }
    } @placeholder {
    <div class="col-xl-4 col-lg-4 col animated fadeInUp fadeIn mb-3">
      <div class="card position-relative border p-3 row-gap-3" aria-hidden="true">
        <span class="placeholder bg-secondary col-12"></span>
        <span class="placeholder bg-secondary col-6"></span>
        <div class="placeholder bg-secondary col-12" style="height: 100px"></div>
        <div class="card-body">
          <h5 class="card-title placeholder-glow">
            <span class="placeholder bg-secondary col-6"></span>
          </h5>
          <p class="card-text placeholder-glow">
            <span class="placeholder bg-secondary col-7"></span>
            <span class="placeholder bg-secondary col-4"></span>
            <span class="placeholder bg-secondary col-4"></span>
            <span class="placeholder bg-secondary col-6"></span>
            <span class="placeholder bg-secondary col-8"></span>
          </p>
        </div>
      </div>
    </div>
    } }
    }
    }
  </div>
</div>
