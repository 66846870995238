<section class="page-wrap animated fadeIn">
  <div class="container">
    <h2 class="section-title"><span>Our Standards</span></h2>

    <div class="row">
      <div class="col-md-6 has-animation fadeInRight">
        <div class="bg-image-wrap text-bottom" style="background-image: url('assets/images/landing/about/procurement/Procurement-min.webp')">
          <h2 class="h2-bold">
            Farmbox Delivery’s
            Procurement Standards
          </h2>
        </div>
      </div>
      <div class="col-md-6 has-animation fadeInLeft">
        <h2 class="text-center text-md-start h2-semibold">We prioritize local, regional, and sustainably grown and produced products.</h2>
        <p class="text-justify">Our standards include rigorous criteria for produce, meats, dairy, eggs, fish & seafood, and artisanal goods.</p>
        <p class="text-justify">At Farmbox Delivery, we prioritize local, regional and sustainably grown and produced products. We foster strong relationships with farmers and artisans to bring you the very best of Western North Carolina and beyond.</p>
        <p class="text-justify">We're driven by a mission to create a resilient system that nourishes our health, land, and livelihoods in Western North Carolina. Join us in celebrating the best our region has to offer!</p>
      </div>
    </div>

    <div class="my-5">
      <div class="row gy-3">
        <div class="col-md-3 col-sm-6" *ngFor="let item of flashCard">
          <div class="flash-card__portrait" style="background-image: url('assets/images/landing/{{item.img}}')">
            <div class="flash-card__title">{{item.title}}</div>
            <div class="flash-card--hover">
              <div class="flash-card__title">{{item.title}}</div>
              <div class="flash-card__desc" [innerHTML]="sanitizeHtml(item.desc)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <a class="btn btn-secondary" routerLink='/shop'>Shop now</a>
    </div>

  </div>
</section>
